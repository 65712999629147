//Macrotech colors

$deepNavy1:#093a70;
$deepNavy2:#38618d;
$deepNavy3:#9db0c6;
$deepNavy4:#e6ebf0;

$navy1:#13649f;
$navy2:#1783b3;
$navy3:#8ec2d9;
$navy4:#e3eff6;

$grayBlue1: #76a9c1;
$grayBlue2: #92bacd;
$grayBlue3: #c9dde6;
$grayBlue4: #f1f6f8;

$olive1:#97b7af;
$olive2:#acc6bf;
$olive3:#d6e2df;
$olive4:#f5f8f7;

$gray1:#cbcccb;
$gray2:#d5d6d5;
$gray3:#e9ebea;
$gray4:#fafafa;

$lightbrown1: #cdc4bb;
$lightbrown2: #d7cfc8;
$lightbrown3: #ece7e4;
$lightbrown4: #fbf9f8;

$leaf1:#98ba71;
$leaf2:#adc78e;
$leaf3:#d6e4c6;
$leaf4:#f5f8f1;

$orange1: #ec9b2e;
$orange2: #f1af5f;
$orange3: #f9d7b0;
$orange4: #fef5ea;



#macrotech {    // root vars
    
    @import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');
    $mtFont: 'Titillium Web', sans-serif;

    --providerLynk: #{$providerLynk};
    --light-grey-green: #{$Light-grey-green};
    --ice-blue: #{$Ice-blue};
    --Ah-darkblue: #{$leaf3};
    --Dark-blue-grey: #{$Dark-blue-grey};
    --steel: #{$Steel};
    --hover-blue: #{$Hover-blue};
    --ah-blue: #{$Ah-blue};
    --light-teal: #{$Light-teal};
    --cloudy-blue: #{$Cloudy-blue};
    --Insurelynk-blue: #{$navy2};
    --pinkish-orange: #{$Pinkish-orange};
    --pale-grey: #{$Pale-grey};
    
    --Myhealthlynk: #{$Myhealthlynk};
    --footer: #{$deepNavy1}; // new var
    --mainFont: #{$mtFont};
    --headerFont: #{$mtFont};


    .logoContainer {
        .logo {
            img {
                content: url("../../img/macroTech/macrotech-logo-web.png");
            }
        }
    }
    #MyForm {
        .btn-primary {
            background-color: $leaf1;
        }
    }
    #desktopheader {
        nav {
            ul {
                background-color: white;
            }
        }
    }
     
    .main-table-style {
        th {
            background-color: $deepNavy2;
            color:white;
        }
    }
    table.dataTable {
        thead {
            .sorting:after {
                opacity: .6;         
                content: "\e150";
            }
        }
    }
    
    navbar {
        ul {
            .logoDiv {   
                .logo {
                    a {      
                        background: no-repeat center center url("../../img/macroTech/macrotech-logo-web.png");  
                        width:250px;
                        height: 80px;
                        background-size: 220px;
                        display:inline-block;
                        
                        img {
                            display: none;
                        }                
                    }
                }
            }
            .navLinks {
                .nav-bucket {
                    a {
                        color:#{$deepNavy1};
                    }
                    a:hover {
                        color:$deepNavy2;
                    }
                }
            }
        }
    }
    .subNavBar {
        header {
            nav {
                ul {
                    background-color: $deepNavy1;                             
                    .navLinks {                  
                        .nav-bucket {                    
                            a {
                                color:white;
                            }
                            a.active, a:hover {
                                color: white;            
                            }        
                            .profile_icon:hover {
                                background-color: #{$deepNavy4};
                            }
                            .nav-bucket-items {                    
                                a {
                                    color:white;
                                    background-color: #{$deepNavy2};
                                }
                                a.active, a:hover {
                                    color: white;
                                    background-color: #{$navy2};        
                                } 
                            }
                        }
                    }
                }
            }
        }
    }
    .new_table_container_search_container {
        .flex_right_col { 
            .center_text a {
                background-color:$deepNavy1;
            }
        }
        .flex_left_col .button_style {
            background-color:$deepNavy1;
        }
        [aria-label]:after {
            background-color: $navy2;
            border-left:6px solid $deepNavy2;
        }
    }

}