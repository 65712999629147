@mixin animation($animation) {
    -webkit-animation: #{$animation};
    -moz-animation: #{$animation};
    -ms-animation: #{$animation};
    -o-animation: #{$animation};
    animation: #{$animation};
}

@mixin box-shadow($box-shadow) {
    -webkit-box-shadow: #{$box-shadow};
    -moz-box-shadow: #{$box-shadow};
    box-shadow: #{$box-shadow};
}

@mixin keyframes($anim) {
    @-webkit-keyframes #{$anim} {
        @content;
    }

    @-moz-keyframes #{$anim} {
        @content;
    }

    @-ms-keyframes #{$anim} {
        @content;
    }

    @keyframes #{$anim} {
        @content;
    }
}

@mixin transform($transform) {
    -webkit-transform: #{$transform};
    -moz-transform: #{$transform};
    -ms-transform: #{$transform};
    transform: #{$transform};
}

@mixin transition($transition) {
    -webkit-transition: #{$transition};
    -moz-transition: #{$transition};
    -ms-transition: #{$transition};
    transition: #{$transition};
}