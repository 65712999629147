// Provider profile form Style
.provider_super_form {
  margin-left: -50px;
  margin-right: -50px;

  .has_new_error{
    border-color: var(--pinkish-orange) !important;
  }

  .paginationBox{
    text-align: center;
    color: #1a2d40;
  }

  .special_tabls{
    border-bottom: 2px solid #5a95cf;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    
    li{
      float: right;
      margin-bottom: -1px;
      position: relative;
      display: block;

      a{
        margin-right: 2px;
        line-height: 1.42857143;
        border: 1px solid transparent;
        border-radius: 4px 4px 0 0;
        padding: 10px 15px;

        &:hover {
          color: #FFF;
          background-color: var(--Dark-blue-grey);
        }

        &:focus{
          color: #FFF;
          background-color: var(--Dark-blue-grey);
        }
      }
    }

    .active{
      a{
        border: 2px solid #5a95cf;
        border-bottom-color: transparent;
        color: #FFF !important;
        background-color: var(--Dark-blue-grey);

        &:hover {
          border: 2px solid #5a95cf;
          border-bottom-color: transparent;
          background-color: var(--Dark-blue-grey);
        }

        &:focus {
          border: 2px solid #5a95cf;
          border-bottom-color: transparent;
        }
      }
    }
  }

  .spanTitle {
    font-family: "Martel", serif;
  }

  #taskIcon {
    font-size: 18px;
  }

  #arrowsPosition {
    position: absolute;
    bottom: -20px;
    right: 10%;
    background-color: white;
    padding: 5px;

    .leftAnchor {
      margin-right: 10px;
    }

    a {
      font-size: 24px;
      cursor: pointer;
      color: var(--Insurelynk-blue);

      &:hover {
        color: var(--Dark-blue-grey);
      }
    }
  }

  .addPhoneEmailBtnStyle {
    background-color: white;
    border-color: white;
    width: auto;
    color: var(--Dark-blue-grey);
    font-weight: bold;
  }

  .specialRow {
    position: relative;

    h3 {
      color: var(--Insurelynk-blue);
    }

    //For the phone and email to scroll when multiple
    .phoneEmailInputsContainer {
      // padding: 1%;
      height: 75px;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    .arrowstyle {
      background-color: var(--Insurelynk-blue);
      border-color: var(--Insurelynk-blue);
      font-weight: bold;
    }

    #leftArrowBtnPosition {
      position: absolute;
      left: 38.5em;
      bottom: -1em;

      .glyphicon {
        color: var(--textWhite);
      }
    }

    #rightArrowBtnPosition {
      position: absolute;
      left: 42.5em;
      bottom: -1em;

      .glyphicon {
        color: var(--textWhite);
      }
    }

    #addAddressBtnPosistion {
      position: absolute;
      left: 3em;
      bottom: -1.2em;

      .addNewAddressBtn {
        background-color: white;
        border-color: white;
        width: auto;
        color: var(--Dark-blue-grey);
        font-weight: bold;

        .glyphicon {
          color: var(--Insurelynk-blue);
        }
      }
    }

    .customAddPhoneEmailBtnPosition {
      position: absolute;
      bottom: 56.5%;
      right: 30%;

      .glyphicon-plus {
        cursor: pointer;
        color: var(--Insurelynk-blue);
        font-size: 20px;
      }
    }

    // #customAddEmailBtnPosition {
    //   position: absolute;
    //   bottom: 6.5%;
    //   right: 35%;
    //
    //   .glyphicon-plus {
    //     cursor: pointer;
    //     color: var(--Insurelynk-blue);
    //     font-size: 20px;
    //   }
    // }
  }

  .scrolling-wrapper {
    width:100%;
    float: left;
    border: solid 2px var(--Insurelynk-blue);
    // height: 310px;
    overflow: hidden;
    // width: 96%;
    white-space: nowrap;

    fieldset {
      padding: 1%;
    }

    .card {
      display: inline-block;
      vertical-align: top;
      width: 100%;
    }
  }

  #custom_width_svg_container {
    padding: 1px;
  }

  .glyphicon-plus {
    cursor: pointer;
    color: var(--Insurelynk-blue);
    font-size: 20px;
  }

  .licRow {
    width: 110%;

    // text-align: center;
    .licenseContainer {
      border: 2px solid var(--pinkish-orange);
      width: 100%;
      margin-right: 1em;

      i {
        display: inline-block;
        font-size: 60px;
        width: 100%;
        height: 100%;
        text-align: center;
        vertical-align: bottom;
        color: var(--Insurelynk-blue);
        // font-size: 50px;
        // text-align:center;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
      }

      img {
        height: 100%;
        width: 100%;
      }
    }

    .uploaded {
      .licenseContainer {
        border: 2px solid var(--Insurelynk-blue);
        // height: 7em;
        width: 100%;
        margin-right: 1em;

        // margin-bottom: 1em;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }

    .missing {
      .licenseContainer {
        border: 2px solid var(--pinkish-orange);
        // height: 7em;
        width: 100%;
        margin-right: 1em;

        // margin-bottom: 1em;
        i {
          color: var(--providerLynk);
        }

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  // #addAddressBtnPosistion {
  //   position: relative;
  //   left: 3em;
  //   bottom: 1.2em;
  //
  //   .addNewAddressBtn {
  //     background-color: white;
  //     border-color: white;
  //     width: auto;
  //     color: var(--Dark-blue-grey);
  //     font-weight: bold;
  //
  //     .glyphicon {
  //       color: var(--Insurelynk-blue);
  //     }
  //   }
  // }
  .deleteBtn {
    padding: 3px 6px;

    .myglyphicon {
      top: 2px;
    }
  }

  #dobStyle {
    display: inline;

    .form-control {
      display: inline;
      width: 80%;
    }

    i {
      color: var(--pinkish-orange);
    }
  }

  .phoneEmailBtn {
    margin-top: 0.5em;
  }

  .form-group {
    position: relative;

    .selectStyle {
      -webkit-appearance: none;
      border-radius: 0;
      border-width: 0 0 0 0;
      border-style: solid;
      border-color: var(--Insurelynk-blue);
      box-shadow: none;
      -webkit-box-shadow: none;
      background: none;
    }
  }

  .addBtnStyle {
    background-color: var(--Insurelynk-blue);
    border-color: var(--Insurelynk-blue);
    width: auto;
    border-radius: 20px;
    color: white;
  }

  h1 {
    color: var(--Insurelynk-blue);
  }

  label {
    color: var(--Dark-blue-grey);
  }

  svg {
    z-index: -1;
    pointer-events: all;

    polyline {
      transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
    }
  }

  .joinInputs {

    .leftSelect {
      // display: block;
      width: 35%;
      height: 36px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: white;
      background-color: var(--Insurelynk-blue);
      border-width: 1px 0 1px 1px;
      border-style: solid;
      border-color: var(--Insurelynk-blue);
      border-radius: 0;
      -webkit-appearance: none;
      float: left;
    }

    .rigthInput {
      // display: block;
      width: 65%;
      // height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 2px solid var(--Insurelynk-blue);
      // border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
      -o-transition: border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
  }

  .addressContainer {
    border: solid 1px var(--Insurelynk-blue);
    padding: 1%;
    height: 330px;
    overflow-x: hidden;
    overflow-y: scroll;

    //chrome webkit
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
      border-radius: 0;
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #555;
    }

    fieldset {
      margin-bottom: 20px;
    }
  }

  // This is Important if I want to add the scroll thing
  // .phoneContainer {
  //   position: relative;
  //
  //   .phoneInputsContainer {
  //     padding: 1%;
  //     height: 90px;
  //     overflow-x: hidden;
  //     overflow-y: scroll;
  //     // border: solid 1px var(--Insurelynk-blue);
  //   }
  // }
  #submitRow {
    text-align: center;
    margin-top: 5%;

    .btn-info {
      background-color: var(--Dark-blue-grey);
      border-color: var(--Dark-blue-grey);
      width: 50%;
      border-radius: 20px;
      text-transform: uppercase;
      font-weight: 600;

      &:hover {
        background-color: var(--Insurelynk-blue);
        border-color: var(--Insurelynk-blue);
        color: white;
      }
    }

    .btnCancel {
      background-color: white;
      color: var(--Dark-blue-grey);
      border-color: var(--Dark-blue-grey);
      width: 50%;
      border-radius: 20px;
      text-transform: uppercase;
      font-weight: 600;

      &:hover {
        background-color: var(--Insurelynk-blue);
        border-color: var(--Insurelynk-blue);
        color: white;
      }
    }
  }

  #customColWidthSvg {
    width: 36%;
    height: 82px;
    margin-top: 2.5em;
    position: relative;
    left: 4%;
    top: 0.6em;
  }

  #customColWidthRadio {
    width: 64%;
  }

  //Custom side nav style
  a,
  a:focus,
  a:hover {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }

  .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
  }

  i,
  span {
    display: inline-block;
    word-break: break-word;
  }

  /* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
  #content {
    padding: 20px;
    // min-height: 100vh;
    transition: all 0.3s;
  }


}

// Provider profile new styles for now on.
.provider_new_super_form{
    margin-top: 0px;
    margin-left: -50px;
    margin-right: -50px;

    // input[type=text], input[type=password], input[type=email], select:not([no-border]) {
    //   font-size: 16px;
    //   ~ label { // direct sibling of all inputs
    //     font-size: 14px;
    //     > span:not(:first-child) {
    //       text-transform: none;
    //     }
    //   }
    // }

    .has_new_error {
      border-color: var(--pinkish-orange) !important;
    }

    #hours_modal{
      .modal-body{
        text-align: initial;

        .form-container{
          padding:0 15px;

          .hours_table{
            width: 100%;
            margin: 15px 0;
            table-layout: fixed;

            thead{
              tr{
                th{
                  font-weight: 200;
                  b{
                    font-weight: 400;
                  }

                  &:nth-child(1){
                    width: 60px;
                    text-align: center;
                  }

                  &:nth-child(2) {
                    width: 130px;
                    padding: 0 20px;
                    text-transform: capitalize;
                  }

                  &:nth-child(3) {
                    padding: 0 20px;
                  }
                }
              }
            }

            tbody{
              tr{
                td{
                  &:nth-child(1) {
                    text-align: center;

                    padding: 20px 0px;
                  }

                  &:nth-child(2) {
                    padding: 20px 20px;
                  }

                  &:nth-child(3) {
                    padding: 10px 20px;

                    .add_hours{
                      &:hover{
                        text-decoration: none;
                        font-weight: 600;
                        color: var(--Insurelynk-blue);
                      }
                    }
                  }

                  .input_hour_group{

                    .input_hour_container {
                      width: 110px;
                      display: inline-block;
                      position: relative;
                      text-align: center;

                      &:nth-child(2) {
                        margin-left: 40px;
                        position: relative;

                        &::before{
                          position: absolute;
                          content: "";
                          width: 15px;
                          height: 2px;
                          display: block;
                          background-color: #000;
                          left: -28px;
                          top: 19px;
                        }
                      }
                      
                      input{
                        width: calc(100% - 0px);
                        float: left;
                        display: inline-block;
                        text-align: center;
                      }

                      // .hour_input{

                      //   &::after {
                      //     content: '';
                      //     width: 39px;
                      //     height: 39px;
                      //     background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><defs><style>.a,.b{fill:none;}.b{stroke:#fff;}.c{stroke:none;}</style></defs><g transform="translate(-629 253)"><rect class="a" width="12" height="12" transform="translate(629 -253)"/><g transform="translate(629.472 -252.528)"><g class="b" transform="translate(0 0)"><circle class="c" cx="5.528" cy="5.528" r="5.528"/><circle class="a" cx="5.528" cy="5.528" r="5.028"/></g><path class="b" d="M0,.807V4.819" transform="translate(5.528 0.709)"/><line class="b" x1="2.929" transform="translate(5.028 5.528)"/></g></g></svg>');
                      //     background-color: var(--Dark-blue-grey);
                      //     display: inline-block;
                      //   }
                      // }

                      .select_possible_times{
                        max-height: 200px;
                        overflow-x: scroll;   
                        position: absolute;
                        z-index: 1;
                        background-color: #fff;
                        width: 100%;
                        box-shadow: 0 1px 4px 0px rgba(0,0,0,.23);
                        top: calc(100% - 5px);

                        .possible_time{
                          border-bottom: 1px solid #cdcdcd;
                          padding: 12px;
                        }
                      }
                    }

                    .remove_hour_span{
                      overflow: hidden;
                      width: 38px;
                      height: 39px;
                      margin-left: 15px;
                      text-align: center;
                      line-height: 39px;
                      display: inline-block;

                      .fas{
                        font-size: 20px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

      }

      .modal-footer {
        text-align: center;

        .err {
          color: var(--pinkish-orange);
        }

        #submit{
          padding: 6px 70px;
        }

        #cancel{
          padding: 6px 40px;
        }
      }
    }

    .address_cards{
      -webkit-transition: .4s;
      transition: .4s;
      height: 100%;
      width: 100%;
      overflow: scroll;
      position: absolute;
      left: 100%;

      .hours_container{
        .no_hours{
          text-align: center;
        }

        .hours_list{
          .hours_rows{
            margin: 20px 0px;
            
            .hours_block {
              display: block;
            }
          }
        }

        button{
          margin: 10px auto;
          width: 50%;
          display: block;
        }
      }

      .lon_lat_map{
        width: 100%;
        transition: all linear 0.5s;
        height: 0px;
        background-color: var(--Insurelynk-blue);
      }

      .show_map{
        height: 300px;
      }

      h5 {
        color: var(--Steel);

        span{
          color: var(--Ah-darkblue);
        }
      }

      .active{
        color: var(--Insurelynk-blue);
      }

      .form-group{
        margin-bottom: 10px !important;
      }
    }

    .scroll_label{
      background-color: var(--Ice-blue);
      overflow: hidden;
      width: 100%;
      height: 25px;
      position: sticky;
      bottom: 0px;

      a{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        text-decoration: none;
        color: var(--Steel);
      }
    }

    .offset{
        left: 0%;
    }

    .editing_container{
        width: 100%;
    }

    .sticky{
        position: sticky;
        top: 0px;
    }

    .column{
        float: left;
        width: calc(50% - 450px);
        padding: 10px 20px;

        ul{
            list-style: none;
            padding:0;
            margin:0;

            li{
                font-size: 16px;
                margin-bottom: 10px;
                font-weight: 600;
                color: var(--Steel);
                overflow: hidden;

                a{
                  color: var(--Steel);
                }

                span{
                  display: block;
                }
            }

            .active{
              color: #5a95cf;
            }

            .tax-id-list-static {
                padding: 0px 0px 0px 30px;
                position: relative;

                a {
                    position: absolute;
                    left: 6px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: var(--Steel);
                    font-size: 20px;
                }

                h4 {
                    color: var(--Dark-blue-grey);
                    margin: 0;
                    font-size: 14px;
                    color:#000;
                }

                p {
                    font-weight: 300;
                    margin: 0;
                    font-size: 14px;
                    color:#000;
                }
            }
        }

        .next_previous_list{

          li{
            transition: all linear 0.1s;
            opacity: 1;

            label{
              text-transform: uppercase;
            }
          }

          .ng-enter,
          .ng-leave{
            opacity: 0;
          }

          .divider{
            font-weight: 300;
            margin: 20px 0px;
          }

          .main{
            color:var(--Dark-blue-grey);
            font-weight: 400;
          }

          .type{
            color: var(--Cloudy-blue);
            font-weight: 400;
          }
        }

        &:first-child{
            text-align: right;
        }
    }

    fieldset{
        border: 1px solid var(--Insurelynk-blue);

        legend {
            padding: 3px 3px;
            width: auto;
            margin-left: 10px;
            border: 0;
            box-sizing: content-box;
            margin-bottom: 5px;
            color: var(--Insurelynk-blue);
            font-size:14px;
            text-transform: uppercase;
            font-weight: 600;

            i {
              color: var(--Steel);
            }
        }
    }

    #offset_scroll_div{
      height: 420px;
      overflow: hidden;
      position: relative;
    }

    .list_of_orgs{
      height: 296px;
      overflow: scroll;
      
      
      .inputs{

        label{
          color:#333333;
        }

        a{
          font-weight: 100;
          color: var(--Steel);
        }
      }
    }

    .main_body_form{
        width: 900px;
        background-color:#fff;
        padding: 10px 60px;
        -webkit-box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.75);

        .section_heading{
          margin-bottom: 25px;

          .section_control{
            color:var(--Steel);
            font-weight: 200;
            font-size: 20px;
          }
        }

        .form-group{
          margin-bottom:25px;
        }

        .delete_add_controls{
          height: 41px;
          position: relative;

          a{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 20px;
            color: var(--Steel);
          }
        }

        .parent-address-container{

          input:required{
            ~ label:after{
              content: '*';
            }
          }

            .arrow_controls{
                position: absolute;
                top: -2px;
                right: 25px;
                margin: 0;

                .fas{
                    margin:0 15px;
                    padding: 0 3px;
                    font-size: 25px;
                    background-color: #FFF;
                }
            }
        }
    }

    @media (max-width:1300px) {
      .main_body_form {
        width: 900px !important;
      }

      .column {
        width: calc(100% - 900px);

        &:nth-child(3n) {
          background-color: blue;
          display: none;
        }
      }
    }

    @media (max-width:1120px) {
      .main_body_form {
        display: block !important;
        width: 100% !important;
      }

      .column {
        display: none;
      }
    }

    .submission_row{
      padding-top: 10px;
      overflow: hidden;
      position: sticky;
      bottom: 0px;
      background-color: #FFF;
      height:50px;
    }
}

.provider_sam_exclusion{
  margin-top: 0px;
  margin-left: -50px;
  margin-right: -50px;
}