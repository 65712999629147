//Variables
$Myhealthlynk: #87bf4a;
$providerLynk: #eab42d;
$Light-grey-green: #bade93;
$Ice-blue: #fbfdff;
$Ah-darkblue: #31465b;
$Dark-blue-grey: #1a2d40;
$Steel: #6f8196;
$Hover-blue: #0c8da9;
$Ah-blue: #3db2cb;
$Light-teal: #9fddea;
$Cloudy-blue: #b4bfcc;
$Insurelynk-blue: #5a95cf;
$Pinkish-orange: #ff754f;
$Pale-grey: #eaf0f6;
$textWhite: #fff;
$ClinicLynk_Purple: #a66cd8; //Breakpoints
$errorRed: #d01313;
$break-large: 1980px;
$break-medium: 1px;

#insurelynk{
    --providerLynk: #{$providerLynk};
    --light-grey-green: #{$Light-grey-green};
    --ice-blue: #{$Ice-blue};
    --Ah-darkblue: #{$Ah-darkblue};
    --Dark-blue-grey: #{$Dark-blue-grey};
    --steel: #{$Steel};
    --hover-blue: #{$Hover-blue};
    --ah-blue: #{$Ah-blue};
    --light-teal: #{$Light-teal};
    --cloudy-blue: #{$Cloudy-blue};
    --Insurelynk-blue: #{$Insurelynk-blue};
    --pinkish-orange: #{$Pinkish-orange};
    --pale-grey: #{$Pale-grey};
    
    --Myhealthlynk: #{$Myhealthlynk};
    --footer: #{$Insurelynk-blue}; // new var
    --mainFont: "Open Sans", sans-serif;
    --headerFont: 'Martel', sans-serif;
}

.form-group{
  position: relative;
}

svg {
  z-index: -1;
  pointer-events: all;

  polyline {
    transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
  }
}

#dashboard-graph{
  padding:10px 0px 0px 0px;
  width: 100%;
}

.legend{
  width:50%;
  margin: 0 auto;
  overflow: hidden;
  span{
    width: 50%;
    display: block;
    text-align: center;
    cursor: pointer;
    color: white;
    float: left;
  }
}

@for $i from 1 through 12 {
  .col-print-#{$i} {
    @media print {
      width: #{percentage(round($i*8.33)/100)};
      float: left;
      text-align: center;
    }
  }
}

@page { 
  size: auto;
}

@media print {
  a[href]:after {
    content: none !important;
  }
}

 /* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */
 .myWrapper {
    display: flex;
    align-items: stretch;
 }

 #sidebar {
    min-width: 250px;
    max-width: 250px;
    background: $Dark-blue-grey;
    color: #fff;
    transition: all 0.3s;
    z-index: 1;

    .list-unstyled{
      li{
        position: relative;

        &:hover{
          .sub_side_navigation {
            display: block;
          }
        }

        .sub_side_navigation{
          position: absolute;
          max-height: 400px;
          bottom: 0;
          left: 92px;
          padding: 0px;
          text-align: left;
          display: none;
          z-index: 1;

          hr{
            margin: 0px;
          }

          a{
            background-color: $Ah-darkblue;
            padding: 20px;
            margin: 0;
            display: block;

            &:first-child{
              border-radius: 0 20px 0px 0;
            }

            &:last-child{
              border-radius: 0 0px 20px 0;
            }
          }
        }

        .exclusion_icon{
          padding: 0 30px;

          .cls-2{
            stroke: #fff;
          }

          .cls-4 {
            fill: #fff;
          }
        }
      }
    }
 }

 #sidebar.active {
    min-width: 80px;
    width: 90px;
    text-align: center;
    position: sticky;
    top: 0px;
 }

 #sidebar.active .CTAs,
 #sidebar.active .sidebar-header h3 {
   display: none;
 }

 #sidebar.active .sidebar-header strong {
   display: block;
 }

 #sidebar ul li a {
   color:#fff;
   text-align: left;
 }

 #sidebar.active ul li a {
   padding: 20px 0;
   text-align: center;
   font-size: 0.85em;
 }

 #sidebar.active ul li a i {
   margin-right: 0;
   display: block;
   font-size: 1.8em;
   margin-bottom: 5px;
 }

 #sidebar.active ul ul a {
   padding: 10px !important;
 }

 #sidebar .sidebar-header {
   padding: 20px;
   background: $Dark-blue-grey;
 }

 #sidebar .sidebar-header strong {
   display: none;
   font-size: 1.8em;
 }

 #sidebar ul li a {
   padding: 10px;
   font-size: 1.1em;
   display: block;
 }

 #sidebar ul li a:hover {
   background: $Insurelynk-blue;
   color: #fff !important;
   text-decoration: none;

    .exclusion_icon {
      padding: 0 30px;

      .cls-2 {
        stroke: #fff !important;
      }

      .cls-4 {
        fill: #fff !important;
      }
    }
 }

 #sidebar ul li a i {
   margin-right: 10px;
 }

 a[data-toggle="collapse"] {
   position: relative;
 }

 ul ul a {
   font-size: 0.9em !important;
   padding-left: 30px !important;
   background: $Dark-blue-grey;
 }

 ul.CTAs {
   padding: 20px;
 }

 ul.CTAs a {
   text-align: center;
   font-size: 0.9em !important;
   display: block;
   border-radius: 5px;
   margin-bottom: 5px;
 }

 a.download {
   background: #fff;
   color: $Dark-blue-grey;
 }

 a.article,
 a.article:hover {
   background: $Dark-blue-grey !important;
   color: #fff !important;
 }

 @include keyframes(in) {
   25% {
     background-size: 0 2px,
       2px 0,
       100% 2px,
       2px 0;
   }

   50% {
     background-size: 0 2px,
       2px 100%,
       100% 2px,
       2px 0;
   }

   75% {
     background-size: 100% 2px,
       2px 100%,
       100% 2px,
       2px 0;
   }

   100% {
     background-size: 100% 2px,
       2px 100%,
       100% 2px,
       2px 100%;
   }
 }

 @include keyframes(out) {
   100% {
     background-size: 0 2px,
       2px 0,
       100% 1px,
       2px 0;
   }

   75% {
     background-size: 0 2px,
       2px 0,
       100% 2px,
       2px 0;
   }

   50% {
     background-size: 0 2px,
       2px 100%,
       100% 2px,
       2px 0;
   }

   25% {
     background-size: 100% 2px,
       2px 100%,
       100% 2px,
       2px 0;
   }

   0% {
     background-size: 100% 2px,
       2px 100%,
       100% 2px,
       2px 100%;
   }
 }