.notificationCenter {
    width: 100%;
    border: none !important;

    .newNotificationBox {
        border: 1px solid var(--Insurelynk-blue);
        text-align: center;
        margin-bottom: 20px;
        cursor: pointer;

        h4 {
            color: var(--Insurelynk-blue);
        }

        &:hover {
            background: var(--Dark-blue-grey);
            h4 {
                color: var(--textWhite);
            }
        }


    }

    .noNotifications {
        text-align: center;

        h3 {
            color: var(--Insurelynk-blue);
            margin-top: 50px;
        }
    }

    .notImg {
        width: 7em;
        padding: 8px;
    }

    .notificationContainerBox {
        height: 550px;
        overflow: scroll;
        margin-bottom: 25px;
    }

    .panel-heading {
        padding: 0;
    }


    .notificationHeader {
        padding: 0px 15px;
        margin-left: 0;
    }


    .notificationRead {
        background-color: var(--textWhite);
    }

    .notificationNotRead {
        background-color: var(--Pale-grey);
    }

    .ilNotificationNotRead {
        background-color: var(--textWhite);
        -webkit-animation: colorChange 5s;
        /* Safari 4.0 - 8.0 */
        animation: colorChange 5s;
    }

    /* Safari 4.0 - 8.0 */
    @-webkit-keyframes colorChange {
        from {
            background: var(--Pale-grey);
        }

        to {
            background: var(--textWhite);
        }
    }

    /* Standard syntax */
    @keyframes colorChange {
        from {
            background: var(--Pale-grey);
        }

        to {
            background: var(--textWhite);
        }
    }

    .headingRow {
        margin-bottom: 10px;
    }

    .modal-content {
        border: 0px solid #5a95cf !important;
        border-radius: 0;
        color: #1a2d40;
    }

    .notificationsBody {
        text-align: left;
    }

    .notificationBox {
        height: 100px;
        background-color: var(--Pale-grey);
        margin-bottom: 5px;
        padding-right: 0px;
    }

    .content {
        float: left;
        width: 81%;
    }

    .optionsBtn {
        float: left;
        // width: 19%;
        background-color: var(--Dark-blue-grey);
        height: 100px;
        text-align: center;

        a {
            color: var(--textWhite);
            position: relative;
            top: 40.5%;
        }
    }



    .loader {
        color: var(--Insurelynk-blue);
        font-size: 20px;
        margin: 100px auto;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        position: relative;
        text-indent: -9999em;
        -webkit-animation: load4 1.3s infinite linear;
        animation: load4 1.3s infinite linear;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
    }

    @-webkit-keyframes load4 {

        0%,
        100% {
            box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
        }

        12.5% {
            box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
        }

        25% {
            box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
        }

        37.5% {
            box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
        }

        50% {
            box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
        }

        62.5% {
            box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
        }

        75% {
            box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
        }

        87.5% {
            box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
        }
    }

    @keyframes load4 {

        0%,
        100% {
            box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
        }

        12.5% {
            box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
        }

        25% {
            box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
        }

        37.5% {
            box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
        }

        50% {
            box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
        }

        62.5% {
            box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
        }

        75% {
            box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
        }

        87.5% {
            box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
        }
    }

}

.notificationBar {
    height: 40px;
    color: white;
    background-color: var(--providerLynk);
    text-align: center;

    &.neutral {
        background-color: var(--Ah-blue);
    }

    a {
        color: white;
        text-decoration: underline;
    }

    .closeButton {
        cursor: pointer;
        float: right; 
        font-size: 26px;
        font-weight: bold;
        padding-top: 6px;
    }

    .seeMore {
        position: absolute;
        z-index: 333;
        top: 100%;
        width: 100%;
        padding: 0 10% 3%;

        text-align: left;
        background-color: var(--pinkish-orange);
    }
}