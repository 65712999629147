.mobileNavContainer2 {
  .navbar {
    background-color: $Insurelynk-blue;
  }

  .navbar-fixed-top {
    top: 50px;
    border-width: 0 0 1px;
  }


  .navbar-default {
    .navbar-nav .open .dropdown-menu > li > a {
      color: $textWhite;
    }

    .navbar-nav > .open > a {
      color: $textWhite;
      background-color: $Insurelynk-blue;

      &:hover {
        color: $textWhite;
        background-color: $Insurelynk-blue;
      }

      &:focus {
        color: $textWhite;
        background-color: $Insurelynk-blue;
      }
    }
  }
}

#mobileNavbar2 {
  .navbar {
    width: 100%;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        text-align: center;
        margin-top: 1%;
        a {
          color: $textWhite;
          &:hover{
            text-decoration: none;
            color: $Dark-blue-grey;
          }
          &:focus{
            text-decoration: none;
            color: $Dark-blue-grey;
          }
        }
      }
    }
  }
}
