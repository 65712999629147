//Vars
@import "globalVars/mixins";
@import "globalVars/vars";
@import "macroTech/macrotech";
//Desktop
@import "desktop/desktop";
@import "desktop/footer";
@import "desktop/populationAnalytics";
//Desktop Pages
@import "desktop/providers/_provider_file_exchange.scss";
@import "desktop/providers/_provider_sam_exclusion.scss";
@import "desktop/providers/certification_files";

@import "desktop/super_administrator/_userList";
@import "desktop/super_administrator/_group";
@import "desktop/super_administrator/_user";
@import "desktop/super_administrator/_manageGroup";
@import "desktop/super_administrator/_userHistory";
@import "desktop/_notificationCenter";
@import "desktop/providers/_customize_provider_credentials";
@import "desktop/providers/provider_profile";
@import "desktop/providers/_relationships";
@import "desktop/providers/_surveys";
@import "components/_checkbox";
@import "desktop/providers/_provider_profile_diffrence";
@import "components/_notification_bar";
@import "components/forms";

// Mobile styles
@import "mobile/_generalStyle";
@import "mobile/mobileNavbar";
@import "mobile/mobileLogin";
@import "mobile/homeMobileView";
@import "mobile/contactUs";
@import "mobile/member/_provider_member_internal_navbar";
@import "mobile/_mainFormStyle";