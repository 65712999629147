.surveyTable {
  tr:nth-child(odd) {
    background-color: $Pale-grey;
  }

  th, td {
    padding: 5px 10px;

    &:not(:last-child) {
      text-align: center;
      border-right: 1px solid $Insurelynk-blue;
    }
  }

  th {
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;

    // sticky headers
    position: sticky; 
    top: 0; 
    border-bottom: 1px solid black; 
    background: white;
    z-index: 2;
  }

  label, label:before { margin: 0 !important }

  .radioYes:checked+label:before {
    background: $Myhealthlynk;
  }

  .radioNo:checked+label:before {
    background: $Pinkish-orange;
  }

  .radioNa:checked+label:before {
    background: $Insurelynk-blue;
  }
}

.surveyHistory {
  margin: 10px auto;
  white-space: nowrap;

  thead, th {
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    background-color: $Dark-blue-grey;
  }

  tbody th {
    text-align: center;
    background-color: $Ah-darkblue;
  }
}

.surveyContainer {
  height: 100%;
  min-height: 400px;
}

.box-invalid {
  border: 1px solid $Pinkish-orange;
  color: $Pinkish-orange;
}

.row-invalid {
  background-color: rgba(234, 180, 45, 0.5) !important;
}

.red {
  color: $Pinkish-orange;
}

.green {
  color: $Myhealthlynk;
}

#fromDate, #toDate {
  margin-bottom: 10px;

  input {
    font-size: 10px;
    height: 26px;
    border: 2px solid $Insurelynk-blue;
    border-radius: 0;
    color: #1a2d40;
    box-shadow: none;
    background: none;
    padding: 0 10px;

    &.invalid {
      border: 2px solid $Pinkish-orange;
    }
  }

  button {
    height: 26px;
    padding: 0 7px 0 7px;
  }

  span {
    font-size: 10px;
  }

  .input-group-btn {
    .btn {
      border: 2px solid $Insurelynk-blue;
      border-radius: 0;
      background-color: $Dark-blue-grey;
      color: #fff;
      border-radius: 0;
    }
    .invalid {
      border: 2px solid $Pinkish-orange;
    }
  }
}

.showPassword {
  position: absolute;
  top: 10px;
  right: 10px;
  color: $Insurelynk-blue;
}