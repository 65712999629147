footer {
    width: 100vw;
    background-color: var(--footer); // new var
    z-index: 1000;

    .navLinks {
      float: right;
      font-weight: 300;
    }
  
    .footer-bucket {
      position: relative;
      height: 50px;
      line-height: 50px;
      color: #FFF;
      padding: 0 1.8vw;
      transition: 0.5s;
  
      &.hideFooter { height: 0; }
  
      .icons {
          width: 24px;
          padding-right: 5px;
      }
  
      a {
        color: #FFF;
      }
    }
}

.translations-keys {
  display: inline;
  text-transform: uppercase;

  &:not(:last-child):after {
      font-weight: normal;
      cursor: default;
      content: ' | ';
  }
}