.groupListContainer{
    h1,
  h4 {
    color: $Insurelynk-blue;
  }

  //Tabs of main table
  .nav {
    // border: 1px solid $Insurelynk-blue;
    border-bottom: 1px solid $Insurelynk-blue;
    font-size: 22px;
    // @media (max-width: 400px) {
    //   li {
    //     border: 1px solid $Insurelynk-blue;
    //     width: 100%;
    //   }
    // }

    li {
      // border-right: 1px solid $Insurelynk-blue;
      // width: 50%;
    }

    .active {
      a {
        color: $Insurelynk-blue;
        background-color: $Dark-blue-grey;
        border-radius: 0;
        border: 1px solid $Insurelynk-blue;
        // border-bottom-color: transparent;
        width: 100%;

        &:hover {
          background: $Dark-blue-grey;
          color: $Insurelynk-blue;
          border: 1px solid $Insurelynk-blue;
        }
        &:focus {
          background: $Dark-blue-grey;
          color: $Insurelynk-blue;
          border: 1px solid $Insurelynk-blue;
        }
      }
    }

    a {
      color: $Ah-darkblue;
      border-radius: 0;
      display: block;
      font-size: 20px;
      font-weight: bold;
      border: 1px solid $Insurelynk-blue;
      margin: 0;
      // padding: 10px 86.45px;
      &:hover {
        background: $Pale-grey;
        width: 100%;
        border: 1px solid $Insurelynk-blue;
      }
    }
  }
  //End of Head Tabs


  //Table style
  .table-bordered {
    border: 1px solid $Insurelynk-blue;
  }

  table {
    border: 1px solid $Insurelynk-blue;
    text-align: center;

    tr {
      th {
        color: #fff;
        background-color: $Dark-blue-grey;
        border: 1px solid $Insurelynk-blue;
        text-align: center;

        input{
          color:#000;
        }
      }

      td {
        border: 1px solid $Insurelynk-blue;
      }
    }
  }

  tr:nth-child(even) {
    background-color: $Cloudy-blue;

    a {
      color: $Ah-darkblue;
      text-decoration: none;
    }
  }

  .boxStyle{
    margin-top: 25px;
  }
}
