@media (max-width: 400px) {
  .wrapper {
    width: 85%;
  }
}

#mobileHomeIcon{
  width: 10em;
}

#mobileHomeContainer{
  margin-top: 20px;

  .customColorWord{
    color: $Insurelynk-blue;
  }
  .mobileHomeHeading{
    text-align: center;
    span{
      color: $Insurelynk-blue;
    }
  }
  .mobileHomeBody{
    margin-top: 25px;
     text-align: justify;
     text-align-last: left;
     padding: 3%;
  }
}
