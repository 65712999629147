@font-face {
  font-family: "ah_r-icons";
  src: url("/fonts/ah_g-icons.ttf") format("truetype");
}

textarea.form-control {
  overflow: auto;
  resize: none;
  z-index: -2;
  border-radius: 0;

  &, &:active, &:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid var(--Insurelynk-blue);
  }
}

.modal {
  text-align: center;
  padding: 0!important;
}

.modal::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.switch-container {
  position: absolute;
  width: 80px;
  left: 0px;
  top: 0px;
}

.btn-switch {
  position: relative;
  display: block;
  width: 50px;
  height: 25px;
  cursor: pointer;
  background-color: #ff754f;
  border: 2px solid #ff754f;
  border-radius: 40px;
}

.btn-switch .btn-switch-circle {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 21px;
  width: 25px;
  background-color: #fff;
  border-radius: 40px;
}

.btn-switch--on {
  background-color: #5a95cf;
  border: 2px solid #5a95cf;
}

.btn-switch--on .btn-switch-circle--on {
  left: auto;
  right: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

[ng-click], a, [data-toggle]{
  cursor: pointer;
}

.input-sm{
  line-height: normal !important;
}

.organization_options{
  span{
    font-size:20px;
    color: var(--Insurelynk-blue);
    text-shadow: 2px 2px var(--Pale-grey);
  }

  ul{
    padding: 5px;
    position: absolute;
    width: 100%;
    background-color: #fff;
    z-index: 1;
    border: 2px solid var(--Insurelynk-blue);
    max-height: 200px;
    overflow-x: scroll;

    li{
      margin-bottom:10px;
      list-style: none;
    }
  }
}

.main-table-style {
  border: 1px solid var(--Insurelynk-blue);
  text-align: center;

  tr {
    th {
      color: #fff;
      background-color: var(--Dark-blue-grey);
      border: 1px solid var(--Insurelynk-blue) !important;
      border-width: 1px 1px 0px 1px !important;
      text-align: center;
      text-transform: uppercase;

      input {
        color: #000;
      }
    }

    td {
      position: relative;
      border: 1px solid var(--Insurelynk-blue);

      .uncomplient {
        color: var(--pinkish-orange);
      }
    }
  }

  tr:nth-child(odd) {
    background-color: var(--Pale-grey);
  }
}

.swal2-container {
  z-index: 111111;
}

.swal2-modal {
  border: 5px solid var(--Insurelynk-blue);
  width: 600px;

  .swal2-title {
    font-family: "Martel", serif;
    font-size: 22px;
  }

  .swal2-content {
    font-size: 16px;
  }

  .swal2-actions {
    button {
      border-radius: 100px !important;
    }

    .swal2-confirm{
      background-color: var(--Dark-blue-grey);
      text-transform: uppercase;

      &:hover{
        background-color: var(--Insurelynk-blue) !important;
      }
    }

    .swal2-cancel{
      background-color: #FFF;
      color: var(--Dark-blue-grey);
      border: 1px solid var(--Dark-blue-grey);
      text-transform: uppercase;
    }
  }
}

//bell style
.badge-notify {
  background: var(--pinkish-orange);
  position: relative;
  top: -14px;
  left: -10px;
}

.badge {
  font-size: 10px;
  padding: 4px 5px;
}


//provider compliance
.uncompliant {
  color: var(--pinkish-orange);
}

.impending_compliance {
  color: var(--providerLynk);
  float: left;
}

.abartys-icon {
  font-family: "ah_r-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  // margin-right: .2em;
  // margin-top: .2em;
  text-align: center;
  vertical-align: -0.225em;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  // margin-left: .2em;

  /* You can be more comfortable with increased icons size */
  font-size: 15px;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

//see all in acivity tables
.seeAllDetail {
  font-weight: normal;
}

//style for headers
.pageTitle {
  color: var(--Insurelynk-blue);

  .iconsize {
    font-size: 20px;
    position: relative;
    bottom: 3px;
  }
}

.leftBtnContainer {
  text-align: right !important;
}

.rigthBtnContainer {
  text-align: left !important;
}

//Centered placeholder
.centerPlaceholder {
  input[placeholder] {
    text-align: center;
  }

  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
  }

  :-ms-input-placeholder {
    text-align: center;
  }
}

// reduced opacity for disabled elements
.disabledStyle {
  opacity: 0.5;
  cursor: not-allowed !important;
}

//Positioning for boostrap calendar
#custom-pos {
  position: relative;
  margin-left: 90%;
}

.dropdown-menu {
  right: auto !important;
}

//form submit btns
.leftSubmitbox {
  text-align: right;
}

.rigthSubmitBox {
  text-align: left;
}

//errorclass for svg in member and provider forms
.has-error-svg {

  .help-block {
    color: var(--pinkish-orange);
  }

  label {
    color: var(--pinkish-orange) !important;
  }

  svg {
    stroke: var(--pinkish-orange) !important;

    .cls-1 {
      stroke: var(--pinkish-orange) !important;
    }
  }

  polyline {
    stroke: var(--pinkish-orange) !important;
  }
}

// Style for provider validation
.temp_green_color{
  color: var(--Myhealthlynk);
}

.validationReportStyle {
  .help-block {
    color: var(--Myhealthlynk);
  }

  label {
    color: var(--Myhealthlynk) !important;
  }

  svg {
    stroke: var(--Myhealthlynk) !important;

    .cls-1 {
      stroke: var(--Myhealthlynk) !important;
    }
  }

  polyline {
    stroke: var(--Myhealthlynk) !important;
  }
}

.validatedPhoneEmailLeftSelect {
  background-color: var(--Myhealthlynk) !important;
  border-color: var(--Myhealthlynk) !important;
}

.validatedPhoneEmailRightInput {
  border: 2px solid var(--Myhealthlynk) !important;
}

//general error
.has-error {
  color: var(--pinkish-orange);
}

.has-error-box {
  border: solid 2px var(--pinkish-orange) !important;
}

//General positioning for member form radio btns
.addressTypeRadioBox {
  position: relative;

  .labelBox {
    position: absolute;
    top: 50px;
    left: 12px;
  }

  .svgBox {
    position: absolute;
    top: 45px;
    left: 50px;
  }

  .radioBox {
    position: absolute;
    top: 2px;
    left: 85px;
  }
}

//Autocomplete style
.typeahead-demo .custom-popup-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  background-color: #f9f9f9;
}

.typeahead-demo .custom-popup-wrapper>.message {
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  color: #868686;
}

.typeahead-demo .custom-popup-wrapper>.dropdown-menu {
  position: static;
  float: none;
  display: block;
  min-width: 160px;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus {
  background-color: var(--Insurelynk-blue);
}

//Selection of item in navbar style
.subNavItemActive {
  font-weight: 550 !important;
}

.sideTabSelected {
  a {
    color: var(--Insurelynk-blue) !important;
    font-weight: bold;

    .exclusion_icon {
      padding: 0 30px;

      .cls-2 {
        stroke: var(--Insurelynk-blue) !important;
      }

      .cls-4 {
        fill: var(--Insurelynk-blue) !important;
      }
    }
  }
}

.dropdownItemSelected {
  background: var(--Insurelynk-blue) !important;
  font-weight: bold;

  &:hover {
    background: var(--Ah-darkblue) !important;
  }
}

////////

.myAlert {
  border: 5px solid var(--Insurelynk-blue);
  border-radius: 0;
  color: #1a2d40;

  h2 {
    font-size: 1.17em;
  }
}

.error {
  width: 100%;
  padding: 10px;
  border: 2px solid #e10000;
  box-shadow: 0 1px 1px var(--errorRed) inset,
    0 0 8px var(--errorRed);
  color: #e10000;
  font-weight: bold;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: table;
  width: 100%;
  height: 100%;

  .btn {
    // border: 2px solid gray;
    color: gray;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 0;
    // font-size: 20px;
    font-weight: bold;
  }
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

* {
  font-family: var(--mainFont);
  font-weight: medium;
}

input.error,
select.error {
  color: #d01313;
  border-color: rgba(191, 74, 74, 0.8);
  box-shadow: 0 1px 1px rgba(191, 35, 35, 0.075) inset,
    0 0 8px rgba(191, 74, 74, 0.6);
  outline: 0 none;
}

label.error {
  color: #d01313;
}

button {
  background-color: var(--Insurelynk-blue);
  color: #fff;
  padding: 9px 20px;
  border-radius: 0;
  border: none;
}

#loadBarSection {
  position: relative;

  #loading-bar {
    margin: 0;
    padding: 0;

    .bar {
      position: absolute;
      margin: 0;
      padding: 0;
      background: var(--Insurelynk-blue);
      height: 5px;
      z-index: 5;
    }
  }
}

.chart-container {
  width: 100%;
  height: 100%;
}

.emailSent {
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

svg {
  -webkit-animation: all 0.3s;
  /* Safari */
  -moz-animation: all 0.3s;
  /*Firefox*/
  -ms-animation: all 0.3s;
  /*IE10+*/
  -o-animation: all 0.3s;
  /*Opera*/
  animation: all 0.3s;
}

html {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overscroll-behavior-y: none;
}

body {
  /* Margin bottom by footer height */
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

h1,
h2 {
  font-family: var(--headerFont);
  font-weight: bold;
}

h1{
  font-size: 32px;
}

label{
  text-transform: capitalize;
}

//Main Nav

#desktopheader {

  // begin of navigation
nav {
  width: 100%;
  z-index: 1002;

  ul {
    min-height: 80px;
    margin: 0;
    background-color: var(--Dark-blue-grey);
    padding-left: 20px;
    transition: 0.5s;

    &.shrink { 
      min-height: 60px; 
      .nav-bucket { line-height: 60px; }
    }

    .logoDiv {
      float: left;
    }

    .navLinks {
      float: right;
      font-weight: 300;
    }

    li {
      list-style: none;

      .nav-bucket {
        position: relative;
        display: inline-block;
        vertical-align: top;
        min-width: 50px;
        line-height: 80px;
        transition: 0.5s;

        .icons {
          width: 21px;
          padding-right: 5px;
        }

        .logo {

          // padding-right: 200px;
          img {
            height: 40px;
          }
        }

        // start nav-bucket-items
        .nav-bucket-items {
          position: absolute;
          overflow: hidden;
          z-index: 5;
          // left: 0;
          height: 0;
          width: 200px;
          min-width: 100%;
          height: 0;
          opacity: 0;
          box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.25);
          -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
          transition: 0.25s;

          a {
            padding: 12px 1.8vw;
            position: relative;
            display: block;
            box-sizing: border-box;
            min-width: 100%;
            padding: 12px 18px;
            line-height: 16px;
            background: var(--Ah-darkblue);
            color: rgba(245, 245, 245, 1);
            // border-color: rgba(255, 206, 31, 1);
            font-size: 12px;
            text-transform: none;
            transition: 0.25s;

            &:after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              height: 0;
              background: var(--Ah-darkblue);
            }

            //Changes the color of the item as it hovers over
            &:hover {
              background: var(--Ah-darkblue);

              &:after {
                left: 0;
                width: 100%;
              }
            }
          }
        }

        // End of nav-bucket-items
        a {
          color: #fff;
          text-decoration: none;
          display: block;
          box-sizing: border-box;
          vertical-align: top;
          height: 100%;
          width: 100%;
          padding: 0 20px;
          text-align: center;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0;
          height: 3px;
          background-color: var(--Insurelynk-blue);
          transition: 0.25s;
        }
      }

      .hoverEvent {
        &:hover {
          .nav-bucket-items {
            overflow: visible;
            height: auto;
            opacity: 1;
            transform: translateY(0);
          }

          &:after {
            left: 0;
            width: 100%;
          }
        }
      }

      .active {
        a:first-of-type {
          font-weight: 550;
          color: var(--Insurelynk-blue);
        }

        &:after {
          left: 0;
          width: 100%;
          background: var(--Dark-blue-grey);
        }
      }
    }
  }
}

  // End of Navigation
}

//Sub NavBar

.subNavBar {
  header {
    // begin of navigation
    nav {
      width: 100%;
      z-index: 1001;

      ul {
        height: 40px; //Change height of sub nav bar
        margin: 0;
        background-color: var(--Ah-darkblue);
        transition: 0.5s;

        &.hideSubnav { 
          height: 0; 

          * { 
            float: none !important; 
          }
        }

        .logoDiv {
          float: left;
        }

        .navLinks {
          float: right;
          font-weight: 300;
        }

        li {
          list-style: none;

          .nav-bucket {
            position: relative;
            display: inline-block;
            vertical-align: top;
            height: 40px; //Change height of sub nav bar text
            min-width: 50px;
            line-height: 40px; //Change height of sub nav bar text

            .icons {
              width: 21px;
              padding-right: 5px;
            }

            .logo {

              // padding-right: 200px;
              img {
                width: 250px;
              }
            }

            // start nav-bucket-items
            .nav-bucket-items {
              position: absolute;
              overflow: hidden;
              z-index: 5;
              top: 40px;
              left: 0;
              height: 0;
              width: 200px;
              min-width: 100%;
              height: 0;
              opacity: 0;
              box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.25);
              -webkit-transform: translateY(-10px);
              transform: translateY(-10px);
              transition: 0.25s;

              a {
                padding: 12px 1.8vw;
                position: relative;
                display: block;
                box-sizing: border-box;
                min-width: 100%;
                padding: 12px 18px;
                line-height: 16px;
                background: var(--Dark-blue-grey);
                color: rgba(245, 245, 245, 1);
                // border-color: rgba(255, 206, 31, 1);
                font-size: 12px;
                text-transform: none;
                transition: 0.25s;

                &:after {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  height: 0;
                  background: var(--Insurelynk-blue);
                }

                //Changes the color of the item as it hovers over
                &:hover {
                  background: var(--Ah-darkblue);

                  &:after {
                    left: 0;
                    width: 100%;
                  }
                }
              }
            }

            // End of nav-bucket-items
            a {
              color: #fff;
              text-decoration: none;
              display: block;
              box-sizing: border-box;
              vertical-align: top;
              height: 100%;
              width: 100%;
              padding: 0 20px;
              text-align: center;
            }

            &:after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 50%;
              width: 0;
              height: 3px;
              background-color: var(--Dark-blue-grey);
              transition: 0.25s;
            }
          }

          .hoverEvent {
            &:hover {
              .nav-bucket-items {
                overflow: visible;
                height: auto;
                opacity: 1;
                transform: translateY(0);
              }

              &:after {
                left: 0;
                width: 100%;
              }
            }
          }

          .active {

            //  font-weight: bold;
            a:first-of-type {
              font-weight: 550;
              // color: var(--Dark-blue-grey);
            }

            &:after {
              left: 0;
              width: 100%;
              background-color: var(--Insurelynk-blue);
            }
          }
        }
      }
    }

    // End of Navigation
    .navIcons {}
  }
}

//End of Header
//Login Style

.loginForm {
  width: 41%;
  margin: 0 auto;
  padding: 75px 30px 40px;

  .welcomeMsg {
    h3 {
      font-weight: bold;
    }
  }

  .logoContainer {
    width: 100%;

    .logo {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  form {
    margin: 0 auto;
    width: 75%;

    hr {
      border-color: var(--Insurelynk-blue);
    }

    .reset {
      text-align: center;
      display: block;
      margin-bottom: 15px;
      margin-top: 15px;

      a {
        color: var(--Insurelynk-blue);
      }
    }

    button {
      border: none;
      background-color: var(--Insurelynk-blue);
      width: 100%;
      border-radius: 20px;
    }

    button[disabled] {
      border: none;
      background-color: var(--Insurelynk-blue);
    }

    button:active,
    button:focus {
      border: none;
      background-color: var(--Insurelynk-blue);
    }

    button:hover {
      border: none;
      background-color: var(--Ah-darkblue);
    }
  }

  .modal-content {
    border: 5px solid var(--Insurelynk-blue);
    border-radius: 0;
    color: #1a2d40;

    input {
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid var(--Insurelynk-blue);
      width: 100%;
      font-size: 16px;
      line-height: 22px;
      padding: 10px 5px;
      outline: 0;
      color: #31465b;
      border-radius: 0;
      box-shadow: inset 0 0 0 transparent;
    }

    select {
      box-shadow: inset 0 0 0 transparent;
      border: 1px solid var(--Insurelynk-blue);
    }

    .form-control {
      display: initial;
      width: 80%;
    }

    .form-group {
      position: relative;
      margin-bottom: 30px;
    }

    #closeBtn {
      color: #333;
      background-color: #fff;
      border-color: #ccc;
      // border-radius: 0;
      width: 10%;
      margin-top: 0;

      &:hover {
        background-color: var(--Pale-grey);
        color: var(--Ah-darkblue);
      }
    }

    .modal-header {
      text-align: center;

      h3 {
        display: inline;
      }

      .IconModal {
        top: -0.1em;
        margin-right: 0.45em;
        color: var(--Ah-darkblue);
      }
    }

    .modal-body {
      text-align: center;

      h5 {
        color: var(--Ah-darkblue);
      }
    }

    .modalBtn {
      color: #fff;
      width: 30%;
      border: none;
      padding: 10px;
      background-color: var(--Insurelynk-blue);
      border-radius: 25px;
      margin-top: 17px;
    }
  }

  .modal-dialog {
    margin-top: 3em;
  }
}

//Registration style

.registrationFrom {
  width: 60%;
  margin: 0 auto;
  padding: 40px 30px 40px;

  .logoContainer {
    width: 100%;
    margin: 0 auto;

    .logo {
      width: 100%;

      img {
        width: 100%;
      }

      h2 {
        text-align: center;
      }
    }
  }

  form {
    margin: 0 auto;
    width: 75%;

    button {
      border: none;
      background-color: var(--Insurelynk-blue);
      width: 100%;
    }

    button[disabled] {
      border: none;
      background-color: var(--Insurelynk-blue);
    }

    button:active,
    button:focus {
      border: none;
      background-color: var(--Insurelynk-blue);
    }

    button:hover {
      border: 1px solid var(--Insurelynk-blue);
      background-color: var(--Dark-blue-grey);
    }
  }
}

//Main Wrapper Style

.wrapper {
  padding: 0 50px;
  width: 100vw;
  flex: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
  transition: 0.5s;

  @media print { 
    padding: 0;
    width: 100%;
    overflow: visible; 
  }

  #MyForm {

    //overflow: hidden;
    .password {
      font-size: 12px;
      color: grey;
    }

    .errorMeassage {
      margin: 20px 0;
      width: 100%;
      padding: 10px;
      border: 2px solid #e10000;
      box-shadow: 0 1px 1px rgba(191, 35, 35, 0.075) inset,
        0 0 8px rgba(191, 74, 74, 0.6);
      color: #e10000;
      font-weight: bold;
    }
  }

  .form-container {
    .errorCallback {
      width: 100%;
      padding: 10px;
      border: 2px solid #e10000;
      box-shadow: 0 1px 1px rgba(191, 35, 35, 0.075) inset,
        0 0 8px rgba(191, 74, 74, 0.6);
      color: #e10000;
      font-weight: bold;
    }

    .form-group {
      position: relative;

      .form-control-feedback {
        top: 25px;
        right: 16px;
      }
    }
  }
}

//End of Main Wrapper Style
//Carousel

.carousel {
  width: 100%;

  img {
    width: 100%;
  }

  .slide_3_img {
    width: 40%;
  }

  .text_slide3_box {
    // text-align: center;
    // margin-top: 15%;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  .highligh_text {
    font-weight: bold;
  }

  .home1_text_container {
    position: absolute;
    top: 30%;
    left: 2.5%;
    text-align: center;
    padding-left: 15%;
    padding-right: 15%;

    #color_detail {
      color: var(--Insurelynk-blue);
    }

    span {
      font-family: "Martel", serif;
    }
  }

  .slick-arrow {
    &:before {
      color: var(--Insurelynk-blue);
    }
  }
}

//End Carousel
//styling for file upload

.base64-file--drop-area {
  width: 100%;
  height: 200px;
  background-color: white;
  border: 2px dashed #31465b;
  color: #31465b;

  .base64-file--file-preview {
    display: none;
  }

  .base64-file--file-name,
  .base64-file--file-remove,
  .base64-file--file-size {
    text-align: left;
    font-weight: bold;
  }

  .base64-file--file-name {
    font-size: 20px;
  }

  .base64-file--file-size {
    width: 50%;
    float: left;
  }

  .base64-file--file-remove {
    width: 50%;
    float: left;
    color: red;
  }
}

//End of styling for file upload

//Terms style

.termsContainer {
  
  #goBackbtn {
    width: 15%;
  }

  .modal-content {
    border: 5px solid var(--Insurelynk-blue);
    border-radius: 0;
    color: #1a2d40;

    input {
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid var(--Insurelynk-blue);
      width: 100%;
      font-size: 16px;
      line-height: 22px;
      padding: 10px 5px;
      outline: 0;
      color: #31465b;
      border-radius: 0;
      box-shadow: inset 0 0 0 transparent;
    }

    select {
      box-shadow: inset 0 0 0 transparent;
      border: 1px solid var(--Insurelynk-blue);
    }

    .form-control {
      display: initial;
      width: 80%;
    }

    .form-group {
      position: relative;
      margin-bottom: 30px;
    }

    #closeBtn {
      color: #333;
      background-color: #fff;
      border-color: #ccc;
      // border-radius: 0;
      width: 10%;
      margin-top: 0;

      &:hover {
        background-color: var(--Pale-grey);
        color: var(--Ah-darkblue);
      }
    }

    #under21Agree {
      width: 20%;

      &:hover {
        background-color: var(--Pale-grey);
        color: var(--Ah-darkblue);
      }
    }

    .modal-header {
      text-align: center;

      h3 {
        display: inline;
      }

      .IconModal {
        top: -0.1em;
        margin-right: 0.45em;
        color: var(--Ah-darkblue);
      }
    }

    .modal-body {
      text-align: center;

      h5 {
        color: var(--Ah-darkblue);
      }
    }

    .modalBtn {
      color: #fff;
      width: 30%;
      border: none;
      padding: 10px;
      background-color: var(--Insurelynk-blue);
      border-radius: 25px;
      margin-top: 17px;
    }
  }

  .modal-dialog {
    margin-top: 3em;
  }

  h1 {
    color: var(--Insurelynk-blue);
  }

  .termsContent {
    border: 2px solid var(--Insurelynk-blue);
    width: 100%;
    height: 30em;
    overflow: scroll;
    padding: 20px;

    h3,
    h4 {
      text-align: center;
    }

    p {
      white-space: pre-wrap;
    }
  }

  .row1 {
    padding-top: 2%;
  }

  .btn {
    color: #fff;
    background-color: var(--Insurelynk-blue);
    margin-top: 1em;
    width: 49%;
    border-radius: 20px;

    &:hover {
      background-color: var(--Pale-grey);
      color: var(--Ah-darkblue);
    }
  }
}

//End of Terms style
//User Logins Style

.ActivityContainer {
  color: var(--Dark-blue-grey);
    width: 100%;

  .globalSearch { 
    border: 2px solid var(--Insurelynk-blue);
    margin: 10px auto;
    height: 36px;
    max-width: 482px;
  }

  .soon_to_be_out_of_compliance {
    color: var(--Dark-blue-grey);
    cursor: pointer;
  }

  .soon_to_be_out_of_compliance_active {
    color: var(--providerLynk);
  }

  .modal {
    .modal-dialog {
      width: 45%;

      .modal-body {
        text-align: left;
        overflow: hidden;

        .checkbox_group {
          overflow: hidden;
          float: left;
          width: 50%;

          .checkbox_container {
            width: 100%;
            float: none;
          }
        }

        .checkbox_container {
          width: 50%;
          float: left;
        }
      }
    }
  }

  .table_contols {
    text-align: right;

    .show_hide {
      margin-right: 110px;
    }

    a {
      color: var(--Dark-blue-grey);
    }

    .highlight {
      color: var(--Insurelynk-blue);
    }
  }

  .paginate_button {
    a {
      border: 0;
    }
  }

  .ticks_container {
    width: 30%;
    min-height: 20px;

    h6 {
      font-weight: 700;
      font-size: 14px;
    }

    .compliance-status {
      width: 50%;
      float: left;

      .checkbox_container {
        width: 100%;
      }
    }

    .types_container {
      width: 35%;
      float: left;
    }

    .status_container {
      width: 65%;
      float: left;

      .checkbox_container {
        float: left;
        width: 50%;
      }
    }
  }

  .ticks_container_override{
    width: 100%;
    float: none;
    // padding: 5px 15px 5px 0px;

    .compliance-status{
      width: 100%;
      float: none;
    }

    .disabled{
      color: var(--Steel);
    }
  }

  .input_search_containers {
    padding-right: 30px;
    width: 40%;
    min-height:20px;

    .from_date_container,
    .to_date_container {
      margin-right: 10px;
      width: 30%;
      float: left;
    }
  }

  .save_status_containers {
    width: 30%;
    min-height:20px;

    .status_links {
      float: left;
      width: 50%;

      .make_report {
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 20px;
        background-color: var(--Dark-blue-grey);
      }
    }

    .save_links {
      float: left;
      width: 50%;
    }
  }

  .ticks_container,
  .input_search_containers,
  .save_status_containers {
    box-sizing: border-box;
    float: left;
  }

  #searchInput,
  #fromDate,
  #toDate {
    margin-bottom: 10px;

    input {
      height: 36px;
      border-width: 2px;
      border-style: solid;
      border-color: #5a95cf;
      border-radius: 0;
      color: #1a2d40;
      box-shadow: none;
      background: none;
    }

    .input-group-btn {
      .btn {
        border-width: 2px;
        border-style: solid;
        border-color: #5a95cf;
        background-color: var(--Dark-blue-grey);
        color: #fff;
        border-radius: 0;
      }
    }
  }

  #fromDate,
  #toDate {
    input {
      font-size: 10px;
      height: 26px;
    }

    button {
      height: 26px;
      padding-top: 0px;
      padding-bottom: 0px;
      padding-right: 7px;
      padding-left: 7px;
    }

    span {
      font-size: 10px;
    }
  }

  .mainRow {
    padding-bottom: 2%;
  }

  .subRow {
    padding-bottom: 4%;
  }

  .dateInput {
    padding-bottom: 5%;
  }

  .controlButtons {
    margin-bottom: 5%;
    width: 120%;
    color: #fff;
    background-color: var(--Insurelynk-blue);
    border-radius: 25px;
  }

  #searchBTN {
    color: #fff;
    background-color: var(--Insurelynk-blue);
    border-radius: 25px;
  }

  h1 {
    color: var(--Insurelynk-blue);
  }

  .table-bordered {
    border: 1px solid var(--Insurelynk-blue);
  }

  //base table
  table {
    border: 1px solid var(--Insurelynk-blue);
    text-align: center;

    tr {
      th {
        color: #fff;
        background-color: var(--Dark-blue-grey);
        border: 1px solid var(--Insurelynk-blue);
        text-align: center;
        text-transform: uppercase;

        input {
          color: #000;
        }
      }

      td {
        position: relative;
        border: 1px solid var(--Insurelynk-blue);
        vertical-align: middle;

        .uncomplient {
          color: var(--pinkish-orange);
        }

        .fa-trash-alt {
          // position:absolute;
          // left: 4px;
          // top: 8px;
          // box-sizing: content-box;
          // border-radius:100%;
          color: var(--providerLynk);
          // border: 1px solid var(--providerLynk);
        }
      }
    }

    tr:nth-child(odd) {
      background-color: var(--Pale-grey);
    }
  }

  tr:nth-child(even) {
    a {
      text-decoration: none;
    }
  }
}

.ActivityContainer_override{
  margin-top: 25px;

  th, td { white-space: nowrap; }

  .center_text{
    text-align: center;
  }

  .top_bar{
    display: flex;

    .title{
      flex: 0 0 300px;
    }

    .new_table_container_search_container{
      flex: 1;
      display: flex;

      [aria-label] {
        position: relative;
      }

      [aria-label]:after {
        content: attr(aria-label);
        display: none;
        position: absolute;
        top: 110%;
        left: 10px;
        pointer-events: none;
        padding: 8px 10px;
        line-height: 15px;
        white-space: nowrap;
        text-decoration: none;
        text-indent: 0;
        overflow: visible;
        font-family: "Open Sans", sans-serif;
        font-size: .9em;
        font-weight: normal;
        color: #FFF;
        background-color: var(--Insurelynk-blue);
        border-left: 6px solid var(--Dark-blue-grey);
        -webkit-border-radius: 2px;
        border-radius: 2px;
        -webkit-box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);
        box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);
      }

      [aria-label]:hover:after,
      [aria-label]:focus:after {
        display: block;
      }

      .flex_left_col{
        flex: 1px;
        display: flex;

        .params_container{
          overflow: hidden;
          flex: 1;

          #searchInput{
            margin: 0;
          }
        }

        .button_style{
          flex: 0 0 10px;
          height: 36px;
          padding: 9px 20px;
          background-color:var(--Insurelynk-blue);
          color: #FFF;
          line-height: 20px;
          // border-radius: 20px;
        }
      }

      .flex_right_col{
        flex: 0 0 340px;

        .center_text{
          width: 50px;
          float: left;

          a{
            background-color: var(--Dark-blue-grey);
            padding:10px 15px;
          }
        }
      }
    }
  }

  .new_table_container {
    width: 100%;
    margin-top: 0px;
    padding-top: 0px;
    display: flex;

    .left_col {
      flex: 0 0 0px;
      overflow: hidden;
      transition: all 0.3s;
      h2 {
        color: var(--Insurelynk-blue);
      }

      .table_filters {
        // border: 2px solid #5a95cf;
        // border-width: 0px 2px 0px 0px;
        min-height: 200px;
        position: sticky;
        width: 190px;
        top: 15px;
        overflow: hidden;
      }
    }

    .show_left_col{
      flex: 0 0 190px;
      border: 1px solid var(--Steel);
      border-width: 0 2px 0 0;
      margin-right: 30px;
    }
    
    .table_container{
      flex: 1;
      overflow: hidden;

      .DTFC_LeftBodyWrapper .dataTables_empty {
        display: none;
      }

      .dataTables_wrapper{

        .dataTables_scroll{
          position: relative;
          &::before{
            box-shadow: inset 7px 0 9px -10px rgba(0, 0, 0, .7);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            content: "";
            width: 0px;
            z-index: 1;
            border-radius: 60px 0px 0px 60px;
          }

          &::after{
            box-shadow: inset -7px 0 9px -10px rgba(0, 0, 0, .7);
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            content: "";
            width: 0px;
            z-index: 1;
            border-radius: 0px 60px 60px 0px;
          } 

          .dataTables_scrollBody{
        
          }
        }

        .show_left_shadow {
          &::before {
            width: 10px;
          }
        }

        .show_right_shadow {
          &::after {
            width: 10px;
          }
        }
      }

      table {
        border: 0px solid #FFF;

        tr {
          th {
            background-color: #FFF;
            color: var(--Insurelynk-blue);
            text-align: left;
            font-weight: 500;
          }

          th,
          td {
            border: 1px solid #FFF !important;
          }
        }

        tbody{
          tr{
            text-align: left;
          }
        }
      }
    }
  }

  .input_search_containers{
    width: 100%;
    .status_links, .message_compliance{
      display: inline-block;
    }
  }
}

//End of User Logins Style
// Health Toolbox Style

.fileManagementContainer {
  color: var(--Dark-blue-grey);
  height: 100%;
  display: flex;
  flex-direction: column;

  .container { margin: 50px auto; }

  .ui-select-match {
    display: block;
    max-height: 200px;
    overflow: scroll;
  }

  .dropzone {
    border: 2px solid var(--Insurelynk-blue);
    min-height: 200px;
    position: relative;
    text-align: center;
    cursor: pointer;

    .fa-check-circle {
      color: #4bb543;
    }

    .uploadBox {
      text-align: center;

      .fa-upload {
        font-size: 3em;
        width: 20%;
        height: 25%;
        overflow: auto;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        cursor: pointer;
        // border: solid black;
      }

      .abartys-icon {
        font-size: 3em;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
      }
    }
  }

  .submit-all {
    background-color: var(--Insurelynk-blue);
    border-radius: 25px;
  }

  .radio-groups {
    .checkbox_container {
      padding: 5px;
      float: left;
    }

    overflow: hidden;
  }

  //Tabs of main table
  .nav {
    border: 1px solid var(--Insurelynk-blue);
    font-size: 22px;

    @media (max-width: 400px) {
      li {
        border: 1px solid var(--Insurelynk-blue);
        width: 100%;
      }
    }

    li {
      border-right: 1px solid var(--Insurelynk-blue);
    }

    .active {
      a {
        color: var(--Insurelynk-blue);
        background-color: var(--Dark-blue-grey);
        border-radius: 0;
        border: 1px solid var(--Dark-blue-grey);
        border-bottom-color: transparent;
        width: 100%;

        &:hover {
          background: var(--Dark-blue-grey);
          color: var(--Insurelynk-blue);
        }

        &:focus {
          background: var(--Dark-blue-grey);
          color: var(--Insurelynk-blue);
        }
      }
    }

    a {
      color: var(--Ah-darkblue);
      border-radius: 0;
      display: block;
      font-size: 20px;
      font-weight: bold;

      // padding: 10px 86.45px;
      &:hover {
        background: var(--Pale-grey);
        width: 100%;
        border-bottom: 1px solid var(--Insurelynk-blue);
      }
    }
  }

  //End of Head Tabs
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid var(--Insurelynk-blue);
    border-top: 1px solid var(--Insurelynk-blue);

    a {
      color: var(--Insurelynk-blue);
      text-decoration: none;
    }
  }

  th {
    color: #FFF;
    background: var(--Dark-blue-grey);
    text-align: center;
    text-transform: uppercase;
  }

  td,
  th {
    border: 1px solid var(--Insurelynk-blue);
    padding: 8px;
  }

  tr:nth-child(odd) {
    background-color: $Cloudy-blue;

    a {
      color: var(--Ah-darkblue);
      text-decoration: none;
    }
  }
}

// End of Health Toolbox Style
//Incentive Program Style

.incentiveProgramContainer {
  color: var(--Dark-blue-grey);
  
  .mainRow {
    padding-bottom: 2%;
  }

  .subRow {
    padding-bottom: 4%;

    .form-group {
      position: relative;
      padding-bottom: 3%;
    }
  }

  #contractYearDiv {
    margin-left: 4%;
  }

  .dateInput {
    padding-bottom: 5%;
  }

  .controlButtons {
    margin-bottom: 5%;
    width: 120%;
    color: #fff;
    background-color: var(--Insurelynk-blue);
    border-radius: 25px;
  }

  #searchBTN {
    color: #fff;
    background-color: var(--Insurelynk-blue);
    border-radius: 25px;
  }

  h1 {
    color: var(--Insurelynk-blue);
  }

  .table-bordered {
    border: 1px solid var(--Insurelynk-blue);
  }

  table {
    border: 1px solid var(--Insurelynk-blue);
    text-align: center;

    tr {
      th {
        color: #fff;
        background-color: var(--Dark-blue-grey);
        border: 1px solid var(--Insurelynk-blue);
        text-align: center;
      }

      td {
        border: 1px solid var(--Insurelynk-blue);

        img {
          width: 15%;
        }
      }
    }
  }

  tr:nth-child(even) {
    background-color: var(--Pale-grey);

    a {
      color: var(--Ah-darkblue);
      text-decoration: none;
    }
  }
}

// End of Incentive Program Style
//Batch Enrollment container

.batchEnrollmentContainer {
  label {
    color: var(--Dark-blue-grey);
  }

  svg {
    z-index: -1;
    pointer-events: all;

    polyline {
      transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
    }
  }

  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px transparent, 0 0 8px transparent;
    box-shadow: inset 0 1px 1px transparent, 0 0 8px transparent;
  }

  .form-group {
    position: relative;

    .selectStyle {
      -webkit-appearance: none;
      border-radius: 0;
      border-width: 0 0 0 0;
      border-style: solid;
      border-color: var(--Insurelynk-blue);
      box-shadow: none;
      -webkit-box-shadow: none;
      background: none;
    }
  }

  form {
    .fa-upload {
      color: var(--Dark-blue-grey);
      font-size: 5em;
      cursor: pointer;
    }

    .fa-check-circle {
      color: #5cb85c;
    }

    .fa-times-circle {
      color: #d9534f;
    }
  }

  .panel-default {
    .panel-body {
      text-align: center;
    }

    .panel-heading {
      color: $textWhite;
      background-color: var(--Insurelynk-blue);
      border-color: #ddd;
    }
  }
}

// Member profile form Style

.member_super_form {
  margin: 0 -50px;

  .paginationBox {
    // position: absolute;
    // top: 26.3em;
    // padding: 1px;
    // left: 40.95em;
    // z-index: 900;
    // background-color: white;
    text-align: center;
    color: var(--Dark-blue-grey);
  }

  #arrowsPosition {
    position: absolute;
    bottom: -20px;
    left: 81%;
    background-color: white;
    padding: 5px;

    .leftAnchor {
      margin-right: 10px;
    }

    a {
      font-size: 24px;
      cursor: pointer;
      color: var(--Insurelynk-blue);

      &:hover {
        color: var(--Dark-blue-grey);
      }
    }
  }

  .customSsnWidth {
    width: 33%;
    padding-right: 1.5%;
    padding-left: 1.5%;
  }

  .addPhoneEmailBtnStyle {
    background-color: white;
    border-color: white;
    width: auto;
    color: var(--Dark-blue-grey);
    font-weight: bold;
  }

  .specialRow {
    position: relative;

    .arrowstyle {
      background-color: var(--Insurelynk-blue);
      border-color: var(--Insurelynk-blue);
      font-weight: bold;
    }

    #leftArrowBtnPosition {
      position: absolute;
      left: 38.5em;
      bottom: -1em;

      .glyphicon {
        color: $textWhite;
      }
    }

    #rightArrowBtnPosition {
      position: absolute;
      left: 42.5em;
      bottom: -1em;

      .glyphicon {
        color: $textWhite;
      }
    }

    #addAddressBtnPosistion {
      position: absolute;
      left: 3em;
      bottom: -1.2em;

      .addNewAddressBtn {
        background-color: white;
        border-color: white;
        width: auto;
        color: var(--Dark-blue-grey);
        font-weight: bold;

        .glyphicon {
          color: var(--Insurelynk-blue);
        }
      }
    }
  }

  .scrolling-wrapper {
    width:100%;
    float: left;
    border: solid 2px var(--Insurelynk-blue);
    // height: 310px;
    overflow: hidden;
    // width: 96%;
    white-space: nowrap;

    fieldset {
      padding: 1%;
      margin-top: 20px;
    }

    .card {
      display: inline-block;
      vertical-align: top;
    }
  }

  #custom_width_svg_container {
    padding: 1px;
  }

  .glyphicon-plus {
    cursor: pointer;
    color: var(--Insurelynk-blue);
    font-size: 20px;
  }

  .licRow {
    width: 110%;

    // text-align: center;
    .licenseContainer {
      border: 2px solid var(--pinkish-orange);
      width: 100%;
      margin-right: 1em;

      i {
        display: inline-block;
        font-size: 60px;
        width: 100%;
        height: 100%;
        text-align: center;
        vertical-align: bottom;
        color: var(--Insurelynk-blue);
        // font-size: 50px;
        // text-align:center;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
      }

      img {
        height: 100%;
        width: 100%;
      }
    }

    .uploaded {
      .licenseContainer {
        border: 2px solid var(--Insurelynk-blue);
        // height: 7em;
        width: 100%;
        margin-right: 1em;

        // margin-bottom: 1em;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }

    .missing {
      .licenseContainer {
        border: 2px solid var(--pinkish-orange);
        // height: 7em;
        width: 100%;
        margin-right: 1em;

        // margin-bottom: 1em;
        i {
          color: var(--providerLynk);
        }

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .customAddBtnPosition {
    position: absolute;
    top: 0.65em;
    right: 14em;

    .glyphicon-plus {
      cursor: pointer;
      color: var(--Insurelynk-blue);
      font-size: 20px;
    }
  }

  // #addAddressBtnPosistion {
  //   position: relative;
  //   left: 3em;
  //   bottom: 1.2em;
  //
  //   .addNewAddressBtn {
  //     background-color: white;
  //     border-color: white;
  //     width: auto;
  //     color: var(--Dark-blue-grey);
  //     font-weight: bold;
  //
  //     .glyphicon {
  //       color: var(--Insurelynk-blue);
  //     }
  //   }
  // }
  .deleteBtn {
    padding: 3px 6px;

    .myglyphicon {
      top: 2px;
    }
  }

  #dobStyle {
    display: inline;

    .form-control {
      display: inline;
      width: 80%;
    }

    i {
      color: var(--pinkish-orange);
    }
  }

  .phoneEmailBtn {
    margin-top: 0.5em;
  }

  .form-group {
    position: relative;

    #ssn1 {
      width: 100%;
      float: left;
      text-security: disc;
      -webkit-text-security: disc;
    }

    #ssn2 {
      // margin: 0 5%;
      width: 100%;
      float: left;
      text-security: disc;
      -webkit-text-security: disc;
    }

    #ssn3 {
      width: 100%;
      // width: calc(100% - 164px);
      float: left;
    }

    .selectStyle {
      -webkit-appearance: none;
      border-radius: 0;
      border-width: 0 0 0 0;
      border-style: solid;
      border-color: var(--Insurelynk-blue);
      box-shadow: none;
      -webkit-box-shadow: none;
      background: none;
    }
  }

  .addBtnStyle {
    background-color: var(--Insurelynk-blue);
    border-color: var(--Insurelynk-blue);
    width: auto;
    border-radius: 20px;
    color: white;
  }

  h1 {
    color: var(--Insurelynk-blue);
  }

  label {
    color: var(--Dark-blue-grey);
    font-weight: 400;
    text-transform: uppercase;
  }

  svg {
    z-index: -1;
    pointer-events: all;

    polyline {
      transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
    }
  }

  .joinInputs {

    .leftSelect {
      // display: block;
      width: 35%;
      height: 36px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: white;
      background-color: var(--Insurelynk-blue);
      border-width: 1px 0 1px 1px;
      border-style: solid;
      border-color: var(--Insurelynk-blue);
      // border: 1px solid #ccc;
      border-radius: 0;
      -webkit-appearance: none;
      float: left;
    }

    .rigthInput {
      // display: block;
      width: 65%;
      // height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 2px solid var(--Insurelynk-blue);
      // border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
      -o-transition: border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
  }

  .addressContainer {
    border: solid 1px var(--Insurelynk-blue);
    padding: 1%;
    height: 330px;
    overflow-x: hidden;
    overflow-y: scroll;

    //chrome webkit
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
      border-radius: 0;
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #555;
    }

    fieldset {
      margin-bottom: 20px;
    }
  }

  .phoneContainer {
    position: relative;

    .phoneInputsContainer {
      padding: 1%;
      height: 90px;
      overflow-x: hidden;
      overflow-y: scroll;
      // border: solid 1px var(--Insurelynk-blue);
    }
  }

  #submitRow {
    text-align: center;
    margin-top: 5%;

    .btn-info {
      background-color: var(--Dark-blue-grey);
      border-color: var(--Dark-blue-grey);
      width: 50%;
      border-radius: 20px;
      text-transform: uppercase;
      font-weight: 600;

      &:hover {
        background-color: var(--Insurelynk-blue);
        border-color: var(--Insurelynk-blue);
        color: white;
      }
    }

    .btnCancel {
      background-color: white;
      color: var(--Dark-blue-grey);
      border-color: var(--Dark-blue-grey);
      width: 50%;
      border-radius: 20px;
      text-transform: uppercase;
      font-weight: 600;

      &:hover {
        background-color: var(--Insurelynk-blue);
        border-color: var(--Insurelynk-blue);
        color: white;
      }
    }
  }

  #customColWidthSvg {
    width: 36%;
    height: 82px;
    margin-top: 2.5em;
    position: relative;
    left: 4%;
    top: 0.6em;
  }

  #customColWidthRadio {
    width: 64%;
  }

  .addRadio {
    margin-top: 2.5em;
    $color-primary: #31cc89;
    $color-dark: #141d49;
    $color-grey: #cccccc;
    $component-font-size: 30px; // changing this will scale everything in the form up/down

    .c-form {
      color: var(--Dark-blue-grey);
      font-size: $component-font-size;
      margin: 2.5em auto;
    }

    .c-form__fieldset {
      border: none;
      padding: 0;

      &+& {
        margin-top: 2.5em;
      }
    }

    .c-form__group {
      margin-top: 0.5em;

      &:first-of-type {
        margin-top: 0;
      }
    }

    // visually hide the native checkbox and radio input
    .c-form__checkbox,
    .c-form__radio {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    // find the label that follows the checked checkbox / radio button in the DOM and show the tick icon
    .c-form__checkbox:checked+.c-form__label .c-form__checkbox-tick,
    .c-form__radio:checked+.c-form__label .c-form__radio-tick {
      stroke-dashoffset: 0;
    }

    // find the label thats follows the focused checkbox / radio button in the DOM and change the circle border color
    .c-form__checkbox:focus+.c-form__label .c-form__checkbox-square,
    .c-form__radio:focus+.c-form__label .c-form__radio-circle {
      stroke: var(--Insurelynk-blue);
      // fill: var(--Insurelynk-blue);
    }

    .c-form__label {
      cursor: pointer;
      display: block;
      font-size: 1em;
    }

    .c-form__label-text {
      margin-left: 0.25em;
      vertical-align: middle;
    }

    .c-form__checkbox-icon,
    .c-form__radio-icon {
      height: 1em;
      width: 1em;
      vertical-align: middle;
    }

    .c-form__checkbox-square,
    .c-form__radio-circle {
      fill: $color-grey;
      stroke: $color-grey;
      stroke-width: 1.5;
      transition: stroke 0.3s ease-in;
    }

    .c-form__checkbox-tick,
    .c-form__radio-tick {
      fill: none;
      stroke: var(--Insurelynk-blue);
      stroke-dasharray: 50;
      /* this is the length of the line */
      stroke-dashoffset: 50;
      /* this is the length of the line */
      stroke-width: 25%;
      transition: stroke-dashoffset 0.5s ease-in;
    }
  }

  a,
  a:focus,
  a:hover {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }

  .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
  }

  i,
  span {
    display: inline-block;
  }

  /* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
  #content {
    padding: 20px;
    // min-height: 100vh;
    transition: all 0.3s;
  }

}

//Provider individual enrollment style

.provider_individual_enrollment_form {
    // min-height: 100vh;
  transition: all 0.3s;

  #arrowsPosition {
    position: absolute;
    bottom: -20px;
    right: 10%;
    background-color: white;
    padding: 5px;

    .leftAnchor {
      margin-right: 10px;
    }

    a {
      font-size: 24px;
      cursor: pointer;
      color: var(--Insurelynk-blue);

      &:hover {
        color: var(--Dark-blue-grey);
      }
    }
  }

  .addPhoneEmailBtnStyle {
    background-color: white;
    border-color: white;
    width: auto;
    color: var(--Dark-blue-grey);
    font-weight: bold;
  }

  a,
  a:focus,
  a:hover {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }

  .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
  }

  i,
  span {
    display: inline-block;
  }

  // margin-top: 5%;

  // margin-left: -2.7%;
  // margin-right: -2.7%;
  .specialRow {
    position: relative;

    h3 {
      color: var(--Insurelynk-blue);
    }

    //For the phone and email to scroll when multiple
    .phoneEmailInputsContainer {
      // padding: 1%;
      height: 75px;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    .arrowstyle {
      background-color: var(--Insurelynk-blue);
      border-color: var(--Insurelynk-blue);
      font-weight: bold;
    }

    #leftArrowBtnPosition {
      position: absolute;
      left: 38.5em;
      bottom: -1em;

      .glyphicon {
        color: $textWhite;
      }
    }

    #rightArrowBtnPosition {
      position: absolute;
      left: 42.5em;
      bottom: -1em;

      .glyphicon {
        color: $textWhite;
      }
    }

    #addAddressBtnPosistion {
      position: absolute;
      left: 3em;
      bottom: -1.2em;

      .addNewAddressBtn {
        background-color: white;
        border-color: white;
        width: auto;
        color: var(--Dark-blue-grey);
        font-weight: bold;

        .glyphicon {
          color: var(--Insurelynk-blue);
        }
      }
    }

    .customAddPhoneEmailBtnPosition {
      position: absolute;
      bottom: 56.5%;
      right: 30%;

      .glyphicon-plus {
        cursor: pointer;
        color: var(--Insurelynk-blue);
        font-size: 20px;
      }
    }

    // #customAddEmailBtnPosition {
    //   position: absolute;
    //   bottom: 6.5%;
    //   right: 35%;
    //
    //   .glyphicon-plus {
    //     cursor: pointer;
    //     color: var(--Insurelynk-blue);
    //     font-size: 20px;
    //   }
    // }
  }

  .scrolling-wrapper {
    width:100%;
    float: left;
    border: solid 2px var(--Insurelynk-blue);
    // height: 310px;
    overflow: hidden;
    // width: 96%;
    white-space: nowrap;

    fieldset {
      padding: 1%;
    }

    .card {
      display: inline-block;
      vertical-align: top;
    }
  }

  #custom_width_svg_container {
    padding: 1px;
  }

  .glyphicon-plus {
    cursor: pointer;
    color: var(--Insurelynk-blue);
    font-size: 20px;
  }

  .licRow {
    width: 110%;

    // text-align: center;
    .licenseContainer {
      border: 2px solid var(--pinkish-orange);
      width: 100%;
      margin-right: 1em;

      i {
        display: inline-block;
        font-size: 60px;
        width: 100%;
        height: 100%;
        text-align: center;
        vertical-align: bottom;
        color: var(--Insurelynk-blue);
        // font-size: 50px;
        // text-align:center;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
      }

      img {
        height: 100%;
        width: 100%;
      }
    }

    .uploaded {
      .licenseContainer {
        border: 2px solid var(--Insurelynk-blue);
        // height: 7em;
        width: 100%;
        margin-right: 1em;

        // margin-bottom: 1em;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }

    .missing {
      .licenseContainer {
        border: 2px solid var(--pinkish-orange);
        // height: 7em;
        width: 100%;
        margin-right: 1em;

        // margin-bottom: 1em;
        i {
          color: var(--providerLynk);
        }

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  // #addAddressBtnPosistion {
  //   position: relative;
  //   left: 3em;
  //   bottom: 1.2em;
  //
  //   .addNewAddressBtn {
  //     background-color: white;
  //     border-color: white;
  //     width: auto;
  //     color: var(--Dark-blue-grey);
  //     font-weight: bold;
  //
  //     .glyphicon {
  //       color: var(--Insurelynk-blue);
  //     }
  //   }
  // }

  .feild_container{
    fieldset {
      border: 1px solid var(--Insurelynk-blue);

      legend {
        padding: 3px 3px;
        width: auto;
        margin-left: 10px;
        border: 0;
        box-sizing: content-box;
        margin-bottom: 5px;
        color: var(--Insurelynk-blue);
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;

        i {
          color: var(--Steel);
        }
      }
    }
  }

  .deleteBtn {
    padding: 3px 6px;

    .myglyphicon {
      top: 2px;
    }
  }

  #dobStyle {
    display: inline;

    .form-control {
      display: inline;
      width: 80%;
    }

    i {
      color: var(--pinkish-orange);
    }
  }

  .phoneEmailBtn {
    margin-top: 0.5em;
  }

  .form-group {
    position: relative;

    .selectStyle {
      -webkit-appearance: none;
      border-radius: 0;
      border-width: 0 0 0 0;
      border-style: solid;
      border-color: var(--Insurelynk-blue);
      box-shadow: none;
      -webkit-box-shadow: none;
      background: none;
    }
  }

  .addBtnStyle {
    background-color: var(--Insurelynk-blue);
    border-color: var(--Insurelynk-blue);
    width: auto;
    border-radius: 20px;
    color: white;
  }

  h1 {
    color: var(--Insurelynk-blue);
  }

  label {
    color: var(--Dark-blue-grey);
    font-weight: 400;
    text-transform: uppercase;
  }

  svg {
    z-index: -1;
    pointer-events: all;

    polyline {
      transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
    }
  }



  .joinInputs {

    .leftSelect {
      // display: block;
      width: 35%;
      height: 36px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: white;
      background-color: var(--Insurelynk-blue);
      border-width: 1px 0 1px 1px;
      border-style: solid;
      border-color: var(--Insurelynk-blue);
      // border: 1px solid #ccc;
      border-radius: 0;
      -webkit-appearance: none;
      float: left;
    }

    .rigthInput {
      // display: block;
      width: 65%;
      // height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 2px solid var(--Insurelynk-blue);
      // border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
      -o-transition: border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
  }

  .addressContainer {
    border: solid 1px var(--Insurelynk-blue);
    padding: 1%;
    height: 330px;
    overflow-x: hidden;
    overflow-y: scroll;

    //chrome webkit
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
      border-radius: 0;
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #555;
    }

    fieldset {
      margin-bottom: 20px;
    }
  }

  .hours_table {
    width: 100%;
    margin: 15px 0;
    table-layout: fixed;

    thead {
      tr {
        th {
          font-weight: 200;

          b {
            font-weight: 400;
          }

          &:nth-child(1) {
            width: 60px;
            text-align: center;
          }

          &:nth-child(2) {
            width: 130px;
            padding: 0 20px;
            text-transform: capitalize;
          }

          &:nth-child(3) {
            padding: 0 20px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:nth-child(1) {
            text-align: center;

            padding: 20px 0px;
          }

          &:nth-child(2) {
            padding: 20px 20px;
          }

          &:nth-child(3) {
            padding: 10px 20px;

            .add_hours {
              &:hover {
                text-decoration: none;
                font-weight: 600;
                color: var(--Insurelynk-blue);
              }
            }
          }

          .input_hour_group {

            .input_hour_container {
              width: 110px;
              display: inline-block;
              position: relative;
              text-align: center;

              &:nth-child(2) {
                margin-left: 40px;
                position: relative;

                &::before {
                  position: absolute;
                  content: "";
                  width: 15px;
                  height: 2px;
                  display: block;
                  background-color: #000;
                  left: -28px;
                  top: 19px;
                }
              }

              input {
                width: calc(100% - 0px);
                float: left;
                display: inline-block;
                text-align: center;
              }

              // .hour_input{

              //   &::after {
              //     content: '';
              //     width: 39px;
              //     height: 39px;
              //     background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><defs><style>.a,.b{fill:none;}.b{stroke:#fff;}.c{stroke:none;}</style></defs><g transform="translate(-629 253)"><rect class="a" width="12" height="12" transform="translate(629 -253)"/><g transform="translate(629.472 -252.528)"><g class="b" transform="translate(0 0)"><circle class="c" cx="5.528" cy="5.528" r="5.528"/><circle class="a" cx="5.528" cy="5.528" r="5.028"/></g><path class="b" d="M0,.807V4.819" transform="translate(5.528 0.709)"/><line class="b" x1="2.929" transform="translate(5.028 5.528)"/></g></g></svg>');
              //     background-color: var(--Dark-blue-grey);
              //     display: inline-block;
              //   }
              // }

              .select_possible_times {
                max-height: 200px;
                overflow-x: scroll;
                position: absolute;
                z-index: 1;
                background-color: #fff;
                width: 100%;
                box-shadow: 0 1px 4px 0px rgba(0, 0, 0, .23);
                top: calc(100% - 5px);

                .possible_time {
                  border-bottom: 1px solid #cdcdcd;
                  padding: 12px;
                }
              }
            }

            .remove_hour_span {
              overflow: hidden;
              width: 38px;
              height: 39px;
              margin-left: 15px;
              text-align: center;
              line-height: 39px;
              display: inline-block;

              .fas {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }

  #submitRow {
    text-align: center;
    margin-top: 5%;

    .btn-info {
      background-color: var(--Dark-blue-grey);
      border-color: var(--Dark-blue-grey);
      width: 50%;
      border-radius: 20px;
      text-transform: uppercase;
      font-weight: 600;

      &:hover {
        background-color: var(--Insurelynk-blue);
        border-color: var(--Insurelynk-blue);
        color: white;
      }
    }

    .btnCancel {
      background-color: white;
      color: var(--Dark-blue-grey);
      border-color: var(--Dark-blue-grey);
      width: 50%;
      border-radius: 20px;
      text-transform: uppercase;
      font-weight: 600;

      &:hover {
        background-color: var(--Insurelynk-blue);
        border-color: var(--Insurelynk-blue);
        color: white;
      }
    }
  }

  #customColWidthSvg {
    width: 36%;
    height: 82px;
    margin-top: 2.5em;
    position: relative;
    left: 4%;
    top: 0.6em;
  }

  #customColWidthRadio {
    width: 64%;
  }
}

//Member individual enrollment style

.member_individual_enrollment_form {
  
  // margin-left: -2.7%;
  // margin-right: -2.7
  .specialRow {
    position: relative;

    .arrowstyle {
      background-color: var(--Insurelynk-blue);
      border-color: var(--Insurelynk-blue);
      font-weight: bold;
    }

    .paginationBox {
      // position: absolute;
      // top: 26.3em;
      // padding: 1px;
      // left: 40.95em;
      // z-index: 900;
      // background-color: white;
      text-align: center;
      color: var(--Dark-blue-grey);
    }

    #arrowsPosition {
      position: absolute;
      bottom: -20px;
      left: 81%;
      background-color: white;
      padding: 5px;

      .leftAnchor {
        margin-right: 10px;
      }

      a {
        font-size: 24px;
        cursor: pointer;
        color: var(--Insurelynk-blue);

        &:hover {
          color: var(--Dark-blue-grey);
        }
      }
    }

    #leftArrowBtnPosition {
      position: absolute;
      left: 81%;
      top: 25.6em;
      background-color: white;
      padding: 15px;

      a {
        font-size: 24px;
        cursor: pointer;
      }
    }

    #rightArrowBtnPosition {
      position: absolute;
      left: 85%;
      top: 25.6em;
      background-color: white;
      padding: 15px;

      a {
        font-size: 24px;
        cursor: pointer;
      }
    }

    #addAddressBtnPosistion {
      position: absolute;
      left: 3em;
      bottom: -1.2em;

      .addNewAddressBtn {
        background-color: white;
        border-color: white;
        width: auto;
        color: var(--Dark-blue-grey);
        font-weight: bold;

        .glyphicon {
          color: var(--Insurelynk-blue);
        }
      }
    }
  }

  .scrolling-wrapper {
    width:100%;
    float: left;
    border: solid 2px var(--Insurelynk-blue);
    // height: 310px;
    overflow: hidden;
    // width: 96%;
    white-space: nowrap;

    fieldset {
      padding: 1%;
      margin-top: 20px;
    }

    .card {
      display: inline-block;
      vertical-align: top;
    }
  }

  #custom_width_svg_container {
    padding: 1px;
  }

  .glyphicon-plus {
    cursor: pointer;
    color: var(--Insurelynk-blue);
    font-size: 20px;
  }

  .licRow {
    width: 110%;

    // text-align: center;
    .licenseContainer {
      border: 2px solid var(--pinkish-orange);
      width: 100%;
      margin-right: 1em;

      i {
        display: inline-block;
        font-size: 60px;
        width: 100%;
        height: 100%;
        text-align: center;
        vertical-align: bottom;
        color: var(--Insurelynk-blue);
        // font-size: 50px;
        // text-align:center;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
      }

      img {
        height: 100%;
        width: 100%;
      }
    }

    .uploaded {
      .licenseContainer {
        border: 2px solid var(--Insurelynk-blue);
        // height: 7em;
        width: 100%;
        margin-right: 1em;

        // margin-bottom: 1em;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }

    .missing {
      .licenseContainer {
        border: 2px solid var(--pinkish-orange);
        // height: 7em;
        width: 100%;
        margin-right: 1em;

        // margin-bottom: 1em;
        i {
          color: var(--providerLynk);
        }

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .customAddBtnPosition {
    position: absolute;
    top: 0.65em;
    right: 14em;

    .glyphicon-plus {
      cursor: pointer;
      color: var(--Insurelynk-blue);
      font-size: 20px;
    }
  }

  // #addAddressBtnPosistion {
  //   position: relative;
  //   left: 3em;
  //   bottom: 1.2em;
  //
  //   .addNewAddressBtn {
  //     background-color: white;
  //     border-color: white;
  //     width: auto;
  //     color: var(--Dark-blue-grey);
  //     font-weight: bold;
  //
  //     .glyphicon {
  //       color: var(--Insurelynk-blue);
  //     }
  //   }
  // }
  .deleteBtn {
    padding: 3px 6px;

    .myglyphicon {
      top: 2px;
    }
  }

  #dobStyle {
    display: inline;

    .form-control {
      display: inline;
      width: 80%;
    }

    i {
      color: var(--pinkish-orange);
      cursor: pointer;
    }
  }

  .phoneEmailBtn {
    margin-top: 0.5em;
  }

  .customSsnWidth {
    width: 33%;
    padding-right: 1.5%;
    padding-left: 1.5%;
  }

  .centerPlaceholder {
    input[placeholder] {
      text-align: center;
    }

    ::-webkit-input-placeholder {
      text-align: center;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      text-align: center;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      text-align: center;
    }

    :-ms-input-placeholder {
      text-align: center;
    }
  }

  .form-group {
    position: relative;

    #ssn1 {
      width: 100%;
      float: left;
      text-security: disc;
      -webkit-text-security: disc;
    }

    #ssn2 {
      // margin: 0 5%;
      width: 100%;
      float: left;
      text-security: disc;
      -webkit-text-security: disc;
    }

    #ssn3 {
      width: 100%;
      // width: calc(100% - 164px);
      float: left;
    }

    .selectStyle {
      -webkit-appearance: none;
      border-radius: 0;
      border-width: 0 0 0 0;
      border-style: solid;
      border-color: var(--Insurelynk-blue);
      box-shadow: none;
      -webkit-box-shadow: none;
      background: none;
    }
  }

  .addBtnStyle {
    background-color: var(--Insurelynk-blue);
    border-color: var(--Insurelynk-blue);
    width: auto;
    border-radius: 20px;
    color: white;
  }

  h1 {
    color: var(--Insurelynk-blue);
  }

  label {
    color: var(--Dark-blue-grey);
    font-weight: 400;
    text-transform: uppercase;
  }

  .typeStyle {
    font-weight: bold;
  }

  .typeOptionsStyle {
    text-transform: capitalize;
  }

  svg {
    z-index: -1;
    pointer-events: all;

    polyline {
      transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
    }
  }

  .joinInputs {

    .leftSelect {
      // display: block;
      width: 35%;
      height: 36px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: white;
      background-color: var(--Insurelynk-blue);
      border-width: 1px 0 1px 1px;
      border-style: solid;
      border-color: var(--Insurelynk-blue);
      // border: 1px solid #ccc;
      border-radius: 0;
      -webkit-appearance: none;
      float: left;
    }

    .rigthInput {
      // display: block;
      width: 65%;
      // height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 2px solid var(--Insurelynk-blue);
      // border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
      -o-transition: border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
  }

  .addressContainer {
    border: solid 1px var(--Insurelynk-blue);
    padding: 1%;
    height: 330px;
    overflow-x: hidden;
    overflow-y: scroll;

    //chrome webkit
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
      border-radius: 0;
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #555;
    }

    fieldset {
      margin-bottom: 20px;
    }
  }

  .phoneContainer {
    position: relative;

    .phoneInputsContainer {
      padding: 1%;
      height: 90px;
      overflow-x: hidden;
      overflow-y: scroll;
      // border: solid 1px var(--Insurelynk-blue);
    }
  }

  .addPhoneEmailBtnStyle {
    background-color: white;
    border-color: white;
    width: auto;
    color: var(--Dark-blue-grey);
    font-weight: bold;
  }

  #submitRow {
    text-align: center;
    margin-top: 5%;

    .btn-info {
      background-color: var(--Dark-blue-grey);
      border-color: var(--Dark-blue-grey);
      width: 50%;
      border-radius: 20px;
      text-transform: uppercase;
      font-weight: 600;

      &:hover {
        background-color: var(--Insurelynk-blue);
        border-color: var(--Insurelynk-blue);
        color: white;
      }
    }

    .btnCancel {
      background-color: white;
      color: var(--Dark-blue-grey);
      border-color: var(--Dark-blue-grey);
      width: 50%;
      border-radius: 20px;
      text-transform: uppercase;
      font-weight: 600;

      &:hover {
        background-color: var(--Insurelynk-blue);
        border-color: var(--Insurelynk-blue);
        color: white;
      }
    }
  }

  #customColWidthSvg {
    width: 36%;
    height: 82px;
    margin-top: 2.5em;
    position: relative;
    left: 4%;
    top: 0.6em;
  }

  #customColWidthRadio {
    width: 64%;
  }

  .addRadio {
    margin-top: 2.5em;
    $color-primary: #31cc89;
    $color-dark: #141d49;
    $color-grey: #cccccc;
    $component-font-size: 30px; // changing this will scale everything in the form up/down

    .c-form {
      color: var(--Dark-blue-grey);
      font-size: $component-font-size;
      margin: 2.5em auto;
    }

    .c-form__fieldset {
      border: none;
      padding: 0;

      &+& {
        margin-top: 2.5em;
      }
    }

    .c-form__group {
      margin-top: 0.5em;

      &:first-of-type {
        margin-top: 0;
      }
    }

    // visually hide the native checkbox and radio input
    .c-form__checkbox,
    .c-form__radio {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    // find the label that follows the checked checkbox / radio button in the DOM and show the tick icon
    .c-form__checkbox:checked+.c-form__label .c-form__checkbox-tick,
    .c-form__radio:checked+.c-form__label .c-form__radio-tick {
      stroke-dashoffset: 0;
    }

    // find the label thats follows the focused checkbox / radio button in the DOM and change the circle border color
    .c-form__checkbox:focus+.c-form__label .c-form__checkbox-square,
    .c-form__radio:focus+.c-form__label .c-form__radio-circle {
      stroke: var(--Insurelynk-blue);
      // fill: var(--Insurelynk-blue);
    }

    .c-form__label {
      cursor: pointer;
      display: block;
      font-size: 1em;
    }

    .c-form__label-text {
      margin-left: 0.25em;
      vertical-align: middle;
    }

    .c-form__checkbox-icon,
    .c-form__radio-icon {
      height: 1em;
      width: 1em;
      vertical-align: middle;
    }

    .c-form__checkbox-square,
    .c-form__radio-circle {
      fill: $color-grey;
      stroke: $color-grey;
      stroke-width: 1.5;
      transition: stroke 0.3s ease-in;
    }

    .c-form__checkbox-tick,
    .c-form__radio-tick {
      fill: none;
      stroke: var(--Insurelynk-blue);
      stroke-dasharray: 50;
      /* this is the length of the line */
      stroke-dashoffset: 50;
      /* this is the length of the line */
      stroke-width: 25%;
      transition: stroke-dashoffset 0.5s ease-in;
    }
  }
}

// Member hl7 style

.member_hl7_files {
  margin: 0 -50px;

  a,
  a:focus,
  a:hover {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }

  .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
  }

  i,
  span {
    display: inline-block;
  }

  /* ---------------------------------------------------
        CONTENT STYLE
    ----------------------------------------------------- */
  #content {
    padding: 20px;
    // min-height: 100vh;
    transition: all 0.3s;
  }

}

//Admin Files STYLE

.member_admin_files {
  margin: 0 -50px;

  .linkStyle {
    cursor: pointer;
    color: var(--Insurelynk-blue) !important;
  }

  .denied {
    color: var(--pinkish-orange) !important;
  }

  .pending {
    color: var(--providerLynk) !important;
  }

  .accepted {
    color: var(--Insurelynk-blue) !important;
  }

  .btn-info {
    background-color: var(--Insurelynk-blue);
    border-color: var(--Insurelynk-blue);
  }

  .btn-danger {
    font-weight: 600;
    border-radius: 25px;
  }

  a,
  a:focus,
  a:hover {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }

  .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
  }

  i,
  span {
    display: inline-block;
  }

  table {
    width: 100%;

    td, th { 
      padding: 4px;
      vertical-align: middle;
    }
  }

  /* ---------------------------------------------------
        CONTENT STYLE
    ----------------------------------------------------- */
  #content {
    padding: 20px;
    // min-height: 100vh;
    transition: all 0.3s;
  }

}

.HDU_contatiner {
  
  .dropzone {
    border: 2px solid var(--Insurelynk-blue);
    min-height: 270px;
    position: relative;
    text-align: center;
    cursor: default;

    .fa-check-circle {
      color: #4bb543;
    }

    .uploadBox {
      text-align: center;

      .fa-upload {
        font-size: 3em;
        width: 20%;
        height: 25%;
        overflow: auto;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        cursor: pointer;
        // border: solid black;
      }
    }

    .dz-message {
      margin: 7em 0;
    }
  }

  .filesList {
    margin: 0 auto;
    width: 100%;
    border: 2px solid var(--Insurelynk-blue);
    max-height: 240px;
    overflow: scroll;

    li {
      position: relative;
      list-style: none;
      clear: both;
      overflow: hidden;
      color: var(--Insurelynk-blue);
      height: 40px;

      .loadProgress {
        height: 40px;
        background-color: var(--Insurelynk-blue);
        opacity: 0.1;
        position: absolute;
        width: 0;
        z-index: 2;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
      }

      .remove {
        position: absolute;
        width: 10%;
        height: 40px;
        z-index: 4;
        left: 0;

        .fa-times {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .fileName {
        position: absolute;
        height: 40px;
        width: 90%;
        z-index: 3;
        right: 0;

        p {
          width: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

#mapSideBar {
  @include transition(all 0.2s ease-in-out);
  left: -400px;
  width: 400px;
  flex: 1;
  background-color: #fff;
  z-index: 2;
  padding: 20px;

  .toggle_container {
    position: absolute;
    display: inline-flex;
    top: 10px;
    right: -300px;
  }

  .inputContainer {
    margin-top: 2em;

    .ui-select-container {
      margin-top: 10px;
      margin-bottom: 20px;
      width: 100%;
    }
  }

  .arrowContainer {
    width: 25px;
    height: 65px;
    position: absolute;
    top: 5px;
    right: -25px;
    background-color: var(--Insurelynk-blue);
    color: #fff;

    .fa-chevron-right {
      height: 25px;
      width: 15px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
      font-size: 25px;
    }
  }
}

.shiftBar {
  left: 0 !important;
}

header ~ div.container.public-form{ // public map list view
  width: 100%;
  #id_range{
    padding: 0px 10px;
  }

  @media screen and (max-width:768px) {
    overflow-y: scroll;
    // max-height: calc(100vh - 260px);
    margin-top: 20px;
    margin-bottom: 20px;

    #range_container{
      padding: 0;
    }

    #submit_container{
      padding: 0;
    }
  }
}

header ~ div.container.list_view {
  overflow-y: scroll;
  max-height: calc(100vh - 209px);
  
  // public map list view
  @media screen and (max-width:768px) {
    overflow-y: scroll;
    max-height: calc(100vh - 209px);
  }
}

#providerMap {
  margin: 0 -50px; 
  width: 100vw;
  height: calc(100vh - 170px);
  flex: 1;

  .mapboxgl-popup {
    max-width: 400px;
    font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  }

  .mapboxgl-popup-content {
    .mapImage {
      width: 50px;
      height: 50px;
      float: left;
    }
  }

  .mapProviderInfo {
    h3 {
      font-size: 19px;
    }

    h4 {
      font-size: 12px;
    }
  }
}

//404 style

.FourOFouContainer {
  h1 {
    color: var(--Dark-blue-grey);
  }

  margin-top: 10%;

  .btn-info {
    background-color: var(--Dark-blue-grey);
    border-color: var(--Dark-blue-grey);
    width: 20%;
    border-radius: 20px;

    &:hover {
      background-color: var(--Pale-grey);
      border-color: var(--Dark-blue-grey);
      color: var(--Ah-darkblue);
    }
  }
}

.public-form {
  margin-top:50px;
  padding:0;
  // margin-bottom: 100px;

  .colorPulse {
    animation: color 2s ease 0s infinite;
  }
}

@keyframes color {
  0% {
    background-color: #fff;
    color: #333;
  }

  50% {
    background-color: var(--Insurelynk-blue);
    color: #fff;
  }

  100% {
    background-color: #fff;
    color: #333;
  }
}