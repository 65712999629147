.contactContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: auto;
  max-width: 1200px;

  h1 {
    color: $Insurelynk-blue;
  }

  #map {
    border: 5px solid $Insurelynk-blue;
  }

  .container{
    .locMap{
      padding: 0;
      margin-right: -15px;
      margin-left: -15px;
      width: calc(100% + 24px);
      position: relative;
    }
  }

  .btn-info {
    background-color: $Dark-blue-grey;
    border-color: $Dark-blue-grey;
    width: 100%;
    border-radius: 20px;

    &:hover {
      background-color: $Pale-grey;
      border-color: $Dark-blue-grey;
      color: $Ah-darkblue;
    }
  }
}