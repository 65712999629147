@keyframes fade_animation {
    0% {
        opacity: 1;
        z-index: 1;

    }

    100% {
        opacity: 0;
        z-index: -20;
    }
}

.provider_profile_diffrence{
    // margin-top: 120px;
    margin-left: -50px;
    margin-right: -50px;

    .mybold{
        font-weight: bold !important;
    }

    .button_container_wrapper{
        background-color: var(--Ice-blue);
        position: sticky;
        bottom: 0px;

        .button_container {
            margin: 0 auto;
            width: 530px;

            button {
                width: auto;
                padding: 6px 46px;
                margin: 15px 5px;
            }
        }
    }

    fieldset {
        border: 1px solid var(--Insurelynk-blue);
        min-height: 250px;

        ul{
            padding: 0px 15px;

            li{
                list-style: none;
                margin-bottom: 10px;
            }

            .excess{
                position: relative;

                p, label{
                    color: var(--pinkish-orange) !important;
                }

                .remove_tax_id{
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size:20px;
                }
            }
        }

        legend {
            padding: 3px 6px;
            width: auto;
            margin-left: 10px;
            border: 0;
            box-sizing: content-box;
            margin-bottom: 5px;
            color: var(--Insurelynk-blue);
            font-size:15px;
            text-transform: uppercase;
            font-weight: 600;
        }

        .tab{
            position: absolute;
            right: 0;
            top: 0;
            font-size: 12px;
            transform: translateY(-50%);

            .active{
                background-color: var(--Insurelynk-blue);
            }

            a{
                color: var(--Steel);
                background-color: var(--Cloudy-blue);
                padding: 5px 20px;
                color: #fff;
                clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
                display: inline-block;
            }
        }
    }

    .arrow_box {
        position: relative;
        background: #88b7d5;
    }

    .arrow_box:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-top-color: #88b7d5;
        border-width: 17px;
        margin-left: -17px;
    }

    .info_group{
        margin: 10px 0px;
        position: relative;

        .new_info{
            background-color: var(--Dark-blue-grey);
            color: #fff;
            padding: 10px;
            border-radius: 5px;
            position: absolute;
            top: -47px;

            p{
                display: inline-block;
            }

            &:after{
                top: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-top-color: var(--Dark-blue-grey);
                border-width: 7px;
                margin-left: -7px;
            }
        }

        .tax_ids{
            max-height: 165px;
            overflow-y: scroll;
        }

        .new_tax_id{
            background-color: var(--Dark-blue-grey);
            list-style: none;
            padding:0 0 30px 0px;
            color: #fff;
            border-radius: 5px;
            position: absolute;
            right: 0px;
            top: -18px;
            z-index: 1;
            min-height: 120px;
            transform: translateX(100%);

            li{
                overflow: hidden;
                clear: both;

                .tax_info{
                    float: left;
                    padding: 10px 20px;
                }

                .add_tax_id{
                    padding: 10px 20px;
                    float: right;
                }
            }

            &:after {
                right: 100%;
                top: 60px;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-right-color: var(--Dark-blue-grey);
                border-width: 7px;
                margin-top: -30px;
            }
        }

        p{
            margin: 0;
        }

        label {
            margin: 0;
            color: var(--Cloudy-blue);
            font-weight:200;
            text-transform: uppercase;
            font-size: 12px;
        }

        .add_all_tin{
            text-align: center;
            position: absolute;
            bottom: 5px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .active_diffrence{
        color: var(--providerLynk);

        label{
            color: var(--providerLynk);
        }
    }

    .active_diffrence_fieldset{

        min-height: 240px;

        legend{
            color: var(--providerLynk);
        }
    }

    .address_groups{
        position: relative;

        .info_group_diff_container{
            background-color: var(--Dark-blue-grey);
            padding: 5px 5px;
            border-radius: 5px;
            position: fixed;
            width: 585px;
            top: 21px;
            left: 25%;
            z-index: 11111;

            &:after {
                top: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-top-color: var(--Dark-blue-grey);
                border-width: 7px;
                margin-left: -7px;
            }

            .info_group_diff {
                width: 100%;
                min-height: 226px;
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                padding: 0px;
                margin: 0px;


                li {
                    flex: 0 0 auto;
                    width: 245px;
                    list-style: none;
                    padding: 10px 10px;
                    background-color: #fff;
                    font-size: 10px;
                    margin: 0px 5px;

                    div {
                        margin-bottom: 10px;

                        label {
                            margin: 0;
                        }

                        p {
                            margin: 0;
                        }
                    }
                }
            }

            .pop_up_tooltip{
                width: 100%;
                height: 236px;
                border-radius: 5px;
                background-color: rgba(26, 45, 64, 0.5);
                color: #fff;
                position: absolute;
                top: 0;
                left: 0;

                animation-fill-mode: forwards;
                animation-duration: 1s;
                animation-name: fade_animation;
                animation-delay: 2s;
            }
        }

        .main_addresses {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;

            .address {
                transition:all 1s;
                flex: 0 0 auto;
                width: 235px;
                padding: 0 10px 10px 10px;
                border: 1px solid var(--Steel);
                border-radius: 2px;
                box-shadow: 0 -1px 0 var(--Steel);
                margin:10px 5px;
                max-height: 270px;
                overflow-x: scroll;
                font-size: 12px;

                .hours_list{
                    clear: both;
                    .hours_rows{
                        overflow: hidden;
                        margin: 10px 0;

                        .day{
                            width: 35%;
                            float: left;
                            text-transform: capitalize;
                        }

                        .hour{
                            width: 65%;
                            float: left;

                            span{
                                display: inline-block;
                            }
                        }
                    }
                }

                li{
                    display: none;
                }

                p{
                    margin-bottom:5px;
                }

                a{
                    text-decoration: underline;
                }

                label{
                    font-weight: 300;
                    color: var(--Cloudy-blue);
                    margin: 0;
                }

                .related_employees{
                    margin-bottom:15px;

                    label {
                        margin: 0;
                    }
                    
                    span{
                        font-weight: 100;
                        display: inline-block;

                        label{
                            font-weight: 100;
                        }

                        .blue{
                            color: var(--Insurelynk-blue);
                        }

                        .grey{
                            color: var(--Cloudy-blue);
                        }
                    }
                }
            }

            .dndDragover{
                background-color: #ebf2f9;
            }
        }
    }
}