.customize_provider_credentials {
    margin-left: -2.7%;
    margin-right: -2.7%;

    // Overwriting bootstrap class
    .container {
        width: 100%;
    }

    // This should be global, Overwriting bootstrap class
    .btn-info {
        background-color: $Dark-blue-grey;
        border-color: $Dark-blue-grey;
        border-radius: 50px;

        &:hover {
            background-color: $Insurelynk-blue;
            border-color: $Insurelynk-blue;
        }
    }


    #view_search_filters_modal,
    #add_edit_filters_modal {
        .modal-lg {
            width: 90%;
        }
    }


    .complianceBtnContainer {
        text-align: center;
    }

    .add_pointer {
        cursor: pointer;
    }


    .calendar_input {
        // border: 1px solid $Insurelynk-blue;
    }


    .require_label_container {
        text-align: right;

        .require_label {
            color: $Ah-darkblue;
        }
    }


    h2 {
        color: $Insurelynk-blue;

        a {
            color: $Insurelynk-blue;
            text-decoration: none;


        }

        i {
            color: $Insurelynk-blue;
            font-size: 18px;


        }
    }

    #submitBtnBox {
        text-align: center;

        .btn-info {
            width: 50%;
        }
    }


    #modalHeadingContainer {
        margin-bottom: 30px;
    }

    .modal-content .modal-body {
        text-align: left;
    }


    .headingColumns {
        height: 100px;
    }

    .headingItemsMargin {
        margin-top: 50px;
    }

    .selected_all_providers {
        color: $Insurelynk-blue;
    }


    .search_btns_container {
        text-align: left;
        margin-bottom: 10px;

        .search_btns {
            width: 50%;
        }
    }

    .searchInput {
        height: 36px;
        border-width: 2px;
        border-style: solid;
        border-color: #5a95cf;
        border-radius: 0;
        color: #1a2d40;
        box-shadow: none;
        background: none;
    }

    a {
        cursor: pointer;
    }

    .checkbox_container {
        width: 100%;
        float: none;
    }


    .specialtyContainer {
        height: 500px;
        overflow: scroll;
    }




    // Panels styles
    .certification-col {
        .header-row {
            background-color: $Dark-blue-grey;
            color: #fff;
            padding: 10px 15px;
            border: 2px solid $Insurelynk-blue;
            border-width: 2px 2px 0px 2px;
            overflow: hidden;

            h4 {
                margin: 0;
                float: left;
                font-size: 19px;
            }

            i {
                float: right;
                font-size: 19px;
            }
        }

        .certification-list {
            border: 2px solid $Insurelynk-blue;
            list-style: none;
            padding: 0;
            max-height: 380px;
            min-height: 380px;
            overflow-x: scroll;

            .card_heading {
                margin-bottom: 15px;
            }

            .file_type_icon_active {
                color: $Ah-darkblue;
            }

            .require_file_by_active {
                color: $Insurelynk-blue !important;
            }

            .file_type_icon_deactivated {
                color: $Cloudy-blue;
            }

            li {
                background-color: #fff;
                display: block;
                padding: 10px 0px;
                border: 1px solid;
                border-width: 4px 2px 2px 2px;
                border-color: $Cloudy-blue;
                margin: 5px;
                height: 150px;
                position: relative;


                #delete_Continer {
                    position: absolute;
                    top: 20px;
                    left: 3%;
                }

                #input_file_type_container {
                    position: absolute;
                    top: 17px;
                    left: 10%;
                }

                #file_filters_icon_container {
                    position: absolute;
                    top: 20px;
                    right: 3%;
                }

                #add_edit_container {
                    position: absolute;
                    top: 60px;
                    left: 3%;
                }

                #issue_date_container {
                    position: absolute;
                    top: 80px;
                    left: 3%;
                }

                #exp_date_container {
                    position: absolute;
                    top: 100px;
                    left: 3%;
                }

                #notify_container {
                    position: absolute;
                    top: 100px;
                    right: 3%;
                }


                #require_file_by_label_container {
                    position: absolute;
                    top: 80px;
                    right: 3%;
                }

                #require_file_by_input_container {
                    position: absolute;
                    top: 100px;
                    right: 3%;
                }





                .icon-container,
                .name-container,
                .controll-container {
                    // height: 100%;
                }

                .icon-container {
                    // position: relative;
                    cursor: pointer;

                    i {
                        // position: absolute;
                        font-size: 25px;
                        // top: 50%;
                        // left: 50%;
                        // transform: translate(-50%, -50%);
                    }
                }

                .delete_file_icon {
                    font-size: 22px;
                }

                .name-container {
                    position: relative;
                    height:40px;

                    .item-text {
                        width: 100%;
                        // position: absolute;
                        font-size: 18px;
                        // top: 50%;
                        // left: 0;
                        // transform: translateY(-50%);
                        // background: none;
                        // border: none;
                    }

                    i {
                        position: absolute;
                        right: -20px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 20px;
                        z-index: 1;
                    }
                }

                .notify_position {
                    text-align: right !important;
                }

                .controll-container {
                    text-align: left;

                    label {
                        margin-bottom: 0;
                        cursor: pointer;
                    }

                    .days {
                        .selected {
                            color: $Insurelynk-blue;
                            font-weight: 600;
                        }
                    }

                    .expires_false {
                        color: $Steel;
                        font-weight: 600;
                    }

                    .expires_true {
                        color: $Insurelynk-blue;
                        font-weight: 600;
                    }

                    .days.expires_false {
                        a {
                            color: $Cloudy-blue;
                        }
                    }

                    input {
                        padding: 0px 1px;
                        width: 90%;
                    }

                    select {
                        -webkit-appearance: none;
                        border-radius: 0;
                        border-width: 0 0 0 0;
                        border-style: solid;
                        border-color: $Insurelynk-blue;
                        box-shadow: none;
                        -webkit-box-shadow: none;
                        background: none;
                    }
                }
            }
        }

        .add_adit_filter {
            color: $Ah-darkblue;
        }
    }



    // Info modal Heading Style

    // This should be global
    .modal_header_abartys {

        text-align: left;
        color: $Insurelynk-blue;
    }

    .info_modal_style {

        text-align: center !important;

        #info_heading {
            text-align: justify;
            line-height: 1.3;
        }

        .info_highlight {
            background-color: $Dark-blue-grey;
            color: $textWhite;
            padding-left: 3px;
            padding-right: 3px;
        }

        #info_plus {
            background-color: $Dark-blue-grey;
            color: $textWhite;
            padding: 2px 3px 2px 3px;
        }

        .custom_close {
            color: $Dark-blue-grey;
        }

        .left_info_col {
            text-align: right;
        }

        .right_info_col {
            text-align: left;
        }

        #left_notfify_info_col {
            #n30 {
                color: $Insurelynk-blue;
                font-weight: bold;
            }

            #n6090 {
                color: $Cloudy-blue;
                font-weight: bold;
            }
        }

        #right_notfify_info_col {

            .pl_yellow {
                color: $providerLynk;
            }
        }

        #require_text {
            line-height: 1.3;
        }

        #add_edit_text {
            color: $Steel;
            font-weight: bold;
        }
    }




}