.populationReportsContainer {  
  position: relative;
  color: var(--Ah-darkblue);
  max-width: 1200px;
  margin: 0 auto;

  &:not(.container) {
    visibility: hidden;
  }

  @media print {
    position: absolute;
    margin-top: -80px;
  }

  .ilBlue { color: var(--Insurelynk-blue); }
  .lightBlue { color: var(--Light-teal); }
  .cloudyBlue { color: var(--Cloudy-blue); }
  .darkBlue { color: var(--Ah-darkblue); }
  .green { color: var(--Myhealthlynk); }
  .yellow { color: var(--providerLynk); }
  .red { color: var(--pinkish-orange); }
  .steel { color: var(--Steel); }

  .font-light { font-weight: 300; }
  .font-normal { font-weight: normal; }
  .font-bold { font-weight: bold; }
  .fs8px { font-size: 8px; }
  .fs12px { font-size: 12px; }
  .fs18px { font-size: 18px; }

  .mt20px { margin-top: 20px; }

  .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .backdrop {
    @extend .flex-column;
    visibility: visible;
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(5px);
    z-index: 1010;

    img {
      width: 15vw;
      height: 15vh;
      margin: 0 auto;
    }

    h1 {
      text-align: center;
      color: white;
    }

    .btn {
      color: var(--Ah-darkblue);
      text-transform: uppercase;
      background-color: white;
      border: 1px solid var(--Ah-darkblue);
      border-radius: 20px;
      width: 100px;
      margin: 0 auto;
    }
  }

  .references {
    @extend .flex-column;
    position: fixed;
    top: 120px;
    left: 0;

    width: 100vw;
    flex: 1;
    background: var(--Ice-blue);
    z-index: 4;

    .col-xs-8 {
      padding: 0 20px;
      overflow-y: auto;
      background: white;
      box-shadow: 0px 3px 6px #00000029;
    }

    .row {
      top: 0;
      display: flex;

      &>* { margin-left: auto; }
    }

    i {
      font-size: 30px;
      align-self: center;
    }
  }

  h1 {
    font-size: 32px;
    white-space: nowrap;
  }

  .listLink {
    padding-left: 5px;
    vertical-align: super;
    color: var(--Cloudy-blue);

    &[ui-sref*="populationAnalyticsList"] { 
      text-decoration: underline;
      color: var(--Insurelynk-blue);
    }

    &:not([ui-sref*="populationAnalyticsList"]):hover { 
      text-decoration: none;
      cursor: default;
    }
  }

  h2 {
    font: 600 18px 'Open Sans', sans-serif;
    text-transform: uppercase;
    color: var(--Insurelynk-blue);
    margin-bottom: auto;
    white-space: nowrap;

    @media print { margin: 0; }
  }

  .mainRow {
    margin: 0 -50px 20px;
    padding: 0 50px;
    background: white;
  }

  .sticky {
    position: sticky;
    background: white;

    @media print { position: relative; }
  }
  
  .header {
    z-index: 3;
    top: 0;
  }

  .ui-select-container {
    padding: 0;
    font-size: 14px;

    .select2-choice { 
      border: 1px solid var(--Insurelynk-blue);
      border-radius: 0;
      background-image: none;
    }

    .select2-arrow {
      border: none;
      border-radius: 0;
      background: var(--Ah-darkblue);
    }

    .ui-select-search { margin-top: 2px; }

    ~label { padding-top: 0; }
  }

  .select2-drop {
    width: 400px;
  }

  .select2-result-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .btn-info {
    font-size: 18px;
    font-weight: 300;
    width: 160px;
    margin-top: 10px;
  }

  .btn-light {
    color: var(--Dark-blue-grey);
    background-color: white;

    &:disabled {
      pointer-events: all;
      cursor: default;
      background-color: white;
      color: var(--Steel);
      border-color: var(--Steel);
    }
  }

  .title {
    top: 110px;
    z-index: 2;
    width: 100%;

    h1 { margin: 10px 0; }
    *, & { transition: 0.5s; }

    &.shrink { 
      h1 { font-size: 16px; }
      .sectionCaret { font-size: 26px; }
    }

    @media print { top: 0; }
  }

  .sectionCaret {
    color: black;
    font-size: 36px;

    @media print { display: none; }
  }

  .saveButton {
    font-size: 16px;
    color: var(--Cloudy-blue);
    text-shadow: 0px 1px 0px var(--Steel);
    float: right;

    i, i:hover, i:active {
      text-decoration: none;
    }
  }

  .cardGroup {
    margin: 0 1.2vw;
    display: flex;

    @media (max-width: 1300px) {
      margin: 0;
    }
  }

  .summaryCard {
    @extend .flex-column;
    text-align: center;
    font-size: 12px;
    
    margin: 0 3px;
    padding: 5px;
    width: 150px;
    height: 200px;
    
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      // IE11 fix
      &>* {
        width: 100%;
        flex: 1 1 auto;
      }
    }

    border: 1px solid var(--Pale-grey);
    border-radius: 2px;
    box-shadow: inset 0 1px var(--Steel);
  }

  .cardTitle {
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 auto;

    span {
      font-weight: normal;
      text-transform: none;
    }
  }

  .cardContent {
    color: black;
    font-weight: 300;
    margin: 0;
    max-width: 90%;

    &.largeNumber { font-size: 50px; }
  }

  .cardIcon {
    font-size: 55px;
  }

  .sexSubtitle {
    color: var(--Light-teal);
    font-size: 10px;
    margin: 0;
    text-transform: lowercase;
  }

  .cardAnchor {
    font-size: 8px;
    text-align: right;
    text-decoration: underline;
    margin-top: auto;
    align-self: flex-end;
  }

  :target::before {
    content: "";
    display: block;
    margin-top: -160px; /* negative fixed header height */
    height: 160px; /* fixed header height*/
    visibility: hidden;
  }

  .healthLegend {
    display: flex;
    justify-content: space-around;
    width: 100%;
    font-size: 8px;

    .square {
      width: 10px;
      height: 10px;
    }

    .ilBlue { background-color: var(--Insurelynk-blue); }
    .lightBlue { background-color: var(--Light-teal); }
    .darkBlue { background-color: var(--Ah-darkblue); }
    .green { background-color: var(--Myhealthlynk); }
    .yellow { background-color: var(--providerLynk); }
    .red { background-color: var(--pinkish-orange); }
  }

  .eatingHabits {
    font-size: 18px;
    text-align: left;
    padding: 0 5px;
    & > p { 
      margin: 0;
      text-transform: capitalize;
    } 
  }

  [tabindex]:focus {
    outline: none;
  }

  .filterPopover {
    display: flex;
    flex-direction: column;

    position: absolute;
    right: -75px;
    background-color: var(--Ah-darkblue);
    box-shadow: 0px -1px 0px var(--Steel);
    border-radius: 2px;
    width: 200px;
    height: 270px;
    padding: 5px;
    font-size: 12px;
    line-height: 1em;

    p { font-size: 9px; }
    label { margin-bottom: 2px; }

    color: white;

    &::after, &::before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &::after {
      border-bottom-color: var(--Ah-darkblue);
      border-width: 10px;
      margin-left: -10px;
    }

    &::before {
      border-width: 10px;
      margin-left: -10px;
    }

    .styled-checkbox, .styled-checkbox-radio {
      &+label:before {
        width: 10px;
        height: 10px;
        vertical-align: middle;
      }

      &+label:after {
        content: unset;
      }
    }

    .btn-filters {
      color: white;
      text-transform: uppercase;
      font-size: 9px;
      padding: 0.3em 3em;
      white-space: nowrap;
      background-color: var(--Dark-blue-grey);
      border: 1px solid white;
      border-radius: 20px;
      width: 150px;
    }

    .ageFilters {
      display: inline-flex;
      align-items: center;
      margin-left: 15px;

      input {
        display: inline-block;
        width: 30px;
        padding: 2px 6px;
      }

      div.inputJoin {
        color: black;
        background-color: white;
        height: 100%;
        padding-top: 5px;
      }

      span {
        color: var(--Cloudy-blue);
        font-size: 10px;
        font-weight: 300;
        padding-left: 2px;
      }
    }
  }

  .reportListContainer {
    margin-top: 5px;
    height: 80px;
    width: 100%;
    overflow-y: auto;
    background-color: var(--Pale-grey);
    border: 1px solid var(--Cloudy-blue);
    transition: height 0.5s ease, width 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 2px 3px;

    &:hover {
      height: 310px;
      width: 700px;
    }
  }
  
  .reportListItem {
    display: inline-block;
    background-color: white;
    box-shadow: -1px 1px 0 var(--Steel);

    margin: 2px;
    padding-left: 5px;

    white-space: nowrap;
    font-size: 12px;
  }

  .reportStatus {
    position: absolute;
    top: 85px;
    left: 0;
    font-size: 12px;
    white-space: nowrap;
    margin-top: 5px;
    width: 100%;
    text-align: center;
  }

  .c3-arcs > path {
    stroke: unset;
  }

  .c3-chart-arc > text {
    font-size: 22px;
  }

  .c3-axis .tick, .c3-legend-item {
    font-size: 14px;
  }

  .c3 text {
    text-transform: capitalize;
  }
  
  .c3-xgrid-focus { display: none; }

  .legend-box {
    width: 10px;
    height: 10px;
    margin: auto 5px;
    flex-shrink: 0;
  }
  
  .legend-item {
    cursor: pointer;
    display: inline-flex;
    margin-right: 5px;
  }
  
  .legend-text {
    width: 100%;
    color: black;
  }
  
  .legend {
    width: 100%;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    text-transform: capitalize;
  }  

  .graphTitles {
    font-size: 26px;
    display: inline-block;

    @media print { margin: 0; }
  }

  .reportFormat {
    text-align: center;
    margin: 0 auto;

    @media print { display: block !important; }
  }

  .sectionBreak {
    margin-top: 80px;
    display: inline-block;
    width: 100%;

    @media print {
      margin: 0;
      page-break-before: always;
      page-break-inside: avoid;
    }
  }

  button.absoluteToggle {
    background-color: var(--Pale-grey);
    color: var(--Insurelynk-blue);
    border-radius: 2px;
    text-shadow: 0px -1px 0px #6F8196;
    padding: 4px 15px;
  }

  .refPane {
    @extend .flex-column;

    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    padding: 10px 20%;

    font-size: 10px;
    background: white;
    backdrop-filter: grayscale(1);
    opacity: 0.9;
    transition: 0.5s;

    @media print { display: none; }
  }

  tbody.riskFactors {
    tr:nth-child(-n+7) > td:last-child::after {
      content: ' *';
    }
  }

  tr {
    th {
      color: #fff;
      background-color: var(--Dark-blue-grey);
      text-align: center;
      border: 2px solid var(--Insurelynk-blue);
      padding: 4px 10px;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;
    }

    td {
      border: 2px solid var(--Insurelynk-blue);
      padding: 2.5px 10px;
      font-size: 12px;
      white-space: nowrap;
    }
  }

  tr:nth-child(even) {
    background-color: var(--Pale-grey);
  }

  .modal-lg {
    min-width: 400px;
    max-width: 1200px;

    .modal-body {
      max-height: 75vh;
      overflow-y: auto;
      
      background:
        /* Shadow covers */
        linear-gradient(white 30%, rgba(255,255,255,0)),
        linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
        
        /* Shadows */
        radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
      background-repeat: no-repeat;
      background-color: white;
      background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
      
      /* Opera doesn't support this in the shorthand */
      background-attachment: local, local, scroll, scroll;
    
    }
  }

  ul.columns {
    list-style-type: none;
    padding: 0;
    columns: 3;
    column-rule: 1px solid var(--Steel);
    text-align: left;

    li { 
      padding: 0 10px;
      column-span: all; 
      margin: auto;
      width: 50%;
    }
    
    li:nth-last-child(n+10), 
    li:nth-last-child(n+10) ~ * {
      column-span: none;
      width: auto;
    }
  }

  #analyticsReportsTable {
    table-layout: fixed;

    td {
      overflow: hidden;
      text-overflow: ellipsis;
      transition: width 0.5s;

      &.moreContent:hover {
        background: inherit;
        position: absolute;
        border-left: none;
        width: auto;
      }
    }
  }
}