.mobileNavContainer {

  .navbar{
    background-color:$Dark-blue-grey;
  }

  .navbar-brand {
    padding: 7px 0 7px 15px;

    img {
      width: 13%;
    }
  }

  .dropdown-menu{
    background-color: $Dark-blue-grey;
  }

  .dropdown-header {
    color: $textWhite;
  }

  .navbar-default {
    .navbar-nav .open .dropdown-menu > li > a {
      color: $textWhite;
    }

    .navbar-nav > .open > a {
      color: $textWhite;
      background-color: $Insurelynk-blue;

      &:hover {
        color: $textWhite;
        background-color: $Insurelynk-blue;
      }

      &:focus {
        color: $textWhite;
        background-color: $Insurelynk-blue;
      }
    }
  }
}


#mobileNavbar{
  .navbar{
    width: 100%;

    ul{
      margin: 0;
      padding: 0;

      li{
        list-style: none;
      }

      .logoDiv{
        max-height:50px;
        height: 50px;
        padding: 10px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        .nav-bucket{
          height: 100%;

          .logo{
            height: 100%;

            img{
              height: 100%;
            }
          }
        }
      }

      .hamstack{
        position: relative;
        float: left;
        width: 50px;
        max-height:50px;
        height: 200px;

        .fa-align-justify{
          position: absolute;
          top:50%;
          left:50%;
          transform: translate(-50%, -50%);
          color:#FFF;
          height: 30px;
          width: 30px;
        }
      }
    }
  }
}

.top-bar-nav{
  height: 50px;
}

.naveParent{
  ul{
    width: 100%;
    padding:0;
    overflow: hidden;
    li{
      list-style: none;
      color:#fff;

      div{
        a{
          color:#fff;
        }
      }
    }

    .hamstack{
      position: relative;
      float: right;
      width: 100%;
      max-height:50px;
      height: 200px;

      .fa-times-circle{
        position: absolute;
        top:50%;
        right:8px;
        transform: translateY(-50%);
        color:#FFF;
        height: 30px;
        width: 30px;
      }
    }

    .navLinks{
      // padding-left:20px;
      float: left;
      width:100%;

      .nav-bucket{
        margin-bottom:20px;

        .parentLink{
          margin-left:20px;
        }

        &:last-child{
          background-color:#000;
        }
      }

      .nav-bucket-items{
        @include transition(all 0.2s ease-in-out);
        background-color:$Insurelynk-blue;
        overflow: hidden;
        height: 0;
        padding-left:20px;

        a{
          display: block;
          padding: 15px 10px;
        }
      }
    }
  }
}

.naveTab{
  @include transition(all 0.2s ease-in-out);
  background-color:$Dark-blue-grey;
  width: 250px;
  z-index:1040;
  position: fixed;
  top: 50px;
  left:-250px;
}

.shiftPosition{
  left:0 !important;
}

.autoHeight{
  height: 300px !important;
}
