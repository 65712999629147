.styled-checkbox {
    position: absolute; // take it out of document flow
    top: 0; left: 0;
    opacity: 0; // hide it
    display: none; // Fixes bug with clicking on top of container activates checkbox 

    &+label {
        position: relative;
        cursor: pointer;
        padding: 0;
        font-weight:400;
    }

    // Box.
    &+label:before {
        content: '';
        margin-right: 5px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: $Cloudy-blue;
    }

    // Box hover
    &:hover+label:before {
        background: $Insurelynk-blue;
    }

    // Box focus
    &:focus+label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked+label:before {
        background: $Insurelynk-blue;
    }

    // Disabled state label.
    &:disabled+label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled+label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked+label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 9px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow:
            2px 0 0 white,
            4px 0 0 white,
            4px -2px 0 white,
            4px -4px 0 white,
            4px -6px 0 white,
            4px -8px 0 white;
        transform: rotate(45deg);
    }
}

.styled-checkbox-radio {
    position: absolute; // take it out of document flow
    top: 0; left: 0;
    opacity: 0; // hide it
    width: 20px;
    height: 20px;
    margin: 0 !important;

    &+label {
        position: relative;
        cursor: pointer;
        padding: 0;
        font-weight: 400;
    }

    // Box.
    &+label:before {
        content: '';
        border-radius: 100%;
        margin-right: 5px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: $Cloudy-blue;
        box-shadow: 0 -1px 0 $Steel;
    }

    // Box hover
    &:hover+label:before {
        border-radius: 100%;
        background: $Insurelynk-blue;
    }

    // Box focus
    &:focus+label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked+label:before {
        background: $Insurelynk-blue;
    }

    // Disabled state label.
    &:disabled+label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled+label:before {
        box-shadow: none;
        background: #ddd;
    }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $Myhealthlynk;
}

input:focus + .slider {
  box-shadow: 0 0 1px $Myhealthlynk;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}