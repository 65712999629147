.provider_sam_exclusion{
    #content{
        width: 85%;
        margin: 0 auto;

        h1 {
            color: var(--Insurelynk-blue);
        }

        .no_exclusions{
            color: var(--Myhealthlynk);
            text-align: center;

            i{
                font-size: 50px;
            }
        }

        .info_card{
            // border: 1px solid var(--Insurelynk-blue);
            box-shadow: 0px 3px 4px 0px var(--Insurelynk-blue);
            border-radius: 34px 34px 34px 34px;
            transition: all .5s;
            margin-bottom: 20px;
            overflow: hidden;
            // max-height: 90px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            min-height: 97px;

            .padding_container{
                height: 100%;
                overflow: hidden;

                .row{
                    // font-weight: 100;
                }
            }

            .my-col-4{
                padding:20px;
                display: flex;
                flex-direction: column;
                flex-basis: 100%;
                flex: 1 0 45%;
                height: 100%;
            }

            .my-col-6 {
                padding: 20px;
                display: flex;
                flex-direction: column;
                flex-basis: 100%;
                flex: 1 0 45%;
                height: 100%;
            }

            .my-col-2 {
                padding: 20px;
                display: flex;
                flex-direction: column;
                flex-basis: 100%;
                flex: 1 0 10%;
                height: 100%;
            }

            .toggle_button{
                height: 100%;
                text-align: center;
                color: var(--Insurelynk-blue);
                line-height: 55px;
                font-size: 25px;
            }
            
            .row{
                margin-bottom: 15px;

                .text_right{
                    text-align: right;
                }

                .display_bold{
                    font-weight: 700;
                    color: var(--Dark-blue-grey);
                }

                &:last-child{
                    margin-bottom: 0;
                }
            }
        }

        // .max_height_animate{
        //     transition: all 1s ease;
        // }
    }

    .fa-chevron-down::before {
        display: block;
        transition: transform 300ms;
    }

    .info_card:not(.collapsed) .fa-chevron-down::before {
        transform: scaleY(-1);
    }
}
