
.provider_file_exchange{
  margin-left: -50px;
  margin-right: -50px;

  .checkbox_container{
      float: left;
      width: 50%;
  }

  #content{
    width: 100%;
    padding: 20px;
    transition: all 0.3s;
  }
  
  .userNameStyle{
    color: var(--Insurelynk-blue);
  }

  //new navs
  .nav {
    border: 1px solid var(--Insurelynk-blue);
    border-bottom: 1px solid var(--Insurelynk-blue);
    font-size: 30px;

    li {
      border-right: 1px solid var(--Insurelynk-blue);
      position: relative;

      .counter{
        position: absolute;
        top: -10px;
        right: -10px;
        font-size: 15px;
        background-color: #5a95cf;
        color: #FFF;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        border-radius: 100%;
      }
    }

    .active {
      a {
        color: var(--Insurelynk-blue);
        background-color: var(--Dark-blue-grey);
        border-radius: 0;
        border: 1px solid var(--Insurelynk-blue);
        border-bottom-color: transparent;
        width: 100%;

        &:hover {
          background: var(--Dark-blue-grey);
          color: var(--Insurelynk-blue);
        }

        &:focus {
          background: var(--Dark-blue-grey);
          color: var(--Insurelynk-blue);
        }
      }
    }

    a {
      color: var(--Insurelynk-blue);
      border-radius: 0;
      display: block;
      font-size: 1em;
      font-weight: bold;

      // padding: 10px 86.45px;
      &:hover {
        color: var(--Insurelynk-blue);
        background: var(--Pale-grey);
        width: 100%;
        border-bottom: 1px solid var(--Insurelynk-blue);
      }
    }
  }

  .dropzone {
    border: 2px solid var(--Insurelynk-blue);
    min-height: 200px;
    position: relative;
    text-align: center;
    cursor: pointer;

    .file-list{
      padding: 0px;

      li{
        list-style:none;
        text-align: left;
        padding:2px 20px;

        span{
          margin:0 10px;
          font-size: 15px;
        }
      }
    }

    .fa-check-circle {
      color: #4bb543;
    }

    .uploadBox {
      text-align: center;
      height: 200px;
      
      .fa-upload {
        font-size: 3em;
        width: 20%;
        height: 25%;
        overflow: auto;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        cursor: pointer;
        // border: solid black;
      }

      .abartys-icon {
        font-size: 3em;
        position: absolute;
        bottom: 50%;
        right: 50%;
        transform: translate(50%, 50%);
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
      }

      .populated_list{
        bottom: 30px;
        right: 30px;
        transform: translate(50%, 50%)
      }
    }

    .dz-message {
      margin: 7em 0;
    }
  }

  .uploadButton{
    background-color: var(--Dark-blue-grey);
    border-radius: 25px;
    margin-top: 2%;
    border: none;
    width: 100%;
    text-transform: uppercase;
    opacity: 1;
  }

  //new table style 
  table {
    .datePos {
      text-align: center;
    }

    .table_date_style {
      padding: 0;
      position: relative;

      .right{
        position: relative;
        float: right;
        width: 55px;
        height: 55px;
        background-color:var(--Dark-blue-grey);
        color:#FFF;

        .abartys-icon{
          position: absolute;
          top:50%;
          left:50%;
          transform: translate(-50%, -50%);
          font-size: 30px;
        }
      }

      .left{
        overflow: hidden;
      }

      .calendar {
        width: 55px;
        height: 55px;
        background-color: var(--Dark-blue-grey);
        position: absolute;
        right: 0px;
        top: 0;
        color: #FFF;
        z-index: 0;

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 22px;
        }
      }

      input {
        width: 100%;
        height: 55px;
        border: none;
        border-radius: 0px;
        padding: 15px 10px;
        background: transparent;
        z-index: 1;
        position: relative;
      }
    }


    // font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid var(--Insurelynk-blue);
    border-top: 1px solid var(--Insurelynk-blue);

    a {
      text-decoration: none;
    }

    .downloadViewStyle {
      text-align: center;
      padding: 0px;

      button {
        background-color: var(--Dark-blue-grey);
        border: none;
        color: #fff;
        width: 80%;
        height: 55px;
      }

      select {
        width: 100%;
      }

      .dropZone {
        float: left;
        color: var(--Dark-blue-grey);
        height: 55px;
        width: 100%;
        // background-color: var(--Dark-blue-grey);
        margin: 0 auto;
        padding: 2px 7px;
        line-height: 55px;

        .zone {
          height: 100%;
          width: 100%;
        }
      }

      .file_selected {
        background-color: var(--Insurelynk-blue);
      }
    }

    thead {
      tr {
        th {
          text-align: center;
          border-bottom: none;
          text-transform: uppercase;
        }

        td {
          border-top: none;
        }
      }
    }

    th {
      color: #fff;
      background-color: var(--Dark-blue-grey);
    }

    td {
      a{
        color:var(--Insurelynk-blue);
      }
      .statusCircle {
        width: 100%;
        height: 39px;
        position: relative;

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%)
        }

        .red {
          color: var(--pinkish-orange);
        }

        .green {
          color: var(--Myhealthlynk);
        }

        .yellow {
          color: var(--providerLynk);
        }
      }
    }

    td,
    th {
      border: 1px solid var(--Insurelynk-blue);
      text-align: left;
      padding: 8px;
      overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }


    tr:nth-child(even) {
      background-color: var(--Pale-grey);

      a {
        text-decoration: none;
      }
    }
  }

  .modal-content{
    .file-containers{
        margin-bottom:15px;
        .file-comment {
            width: 100%;
            border: 2px solid var(--Insurelynk-blue);
        }
    }
  }
}