input[type=text], input[type=password], input[type=email], select:not([no-border]), textarea, .ui-select-container {
    -webkit-appearance: none;
    display: block;
    background-color: #fff;
    border: 0;
    width: 100%;
    // max-width: 500px;
    font-size: 16px;
    line-height: 19.2px;
    padding:10px;
    outline: 0;
    color: $Ah-darkblue;

    ~ label { // direct sibling of all inputs
        font-size: 14px;
        font-weight: normal;
        text-align: left;
        text-transform: uppercase;
        padding-top: 5px;

        > span:not(:first-child) {
            text-transform: none;
        }
    }
}

.joinInputs{
    ~label {
        // direct sibling of all inputs
        font-size: 14px;
        font-weight: normal;
        text-align: left;
        text-transform: uppercase;
        padding-top: 5px;

        >span:not(:first-child) {
            text-transform: none;
        }
    }
}

input[type=text]:not([no-border]), input[type=password]:not([no-border]),
input[type=email]:not([no-border]){
    background-repeat: no-repeat;
    background-image:   linear-gradient(to right, $Insurelynk-blue 100%, $Insurelynk-blue 100%),
                        linear-gradient(to top, $Insurelynk-blue 100%, $Insurelynk-blue 100%),
                        linear-gradient(to left, $Insurelynk-blue 100%, $Insurelynk-blue 100%),
                        linear-gradient(to bottom, $Insurelynk-blue 100%, $Insurelynk-blue 100%);
    background-size:    100% 2px,
                        2px 100%,
                        100% 2px,
                        2px 100%;
    background-position:    100% 0,
                            100% 100%,
                            0 100%,
                            0 0;
    @include animation('out 1.25s ease-out forwards');

    &:hover:not([disabled]) {
        box-shadow: inset 0 -2px $Insurelynk-blue;
        @include transition('box-shadow 0.5s');
    }

    &:focus:not([disabled]) {
        background-size: 0 2px, 2px 0, 100% 2px, 2px 0;
        @include animation('in 1.25s ease-out forwards');
    }

    &[no-border] {
        border-bottom: 2px solid $Insurelynk-blue;
        background-image: none;
        box-shadow: none;
    }

    &[dark-border] {
        background-image:   linear-gradient(to right, $Ah-darkblue 100%, $Ah-darkblue 100%),
                            linear-gradient(to top, $Ah-darkblue 100%, $Ah-darkblue 100%),
                            linear-gradient(to left, $Ah-darkblue 100%, $Ah-darkblue 100%),
                            linear-gradient(to bottom, $Ah-darkblue 100%, $Ah-darkblue 100%);

        &:hover {
            box-shadow: inset 0 -2px $Ah-darkblue;
        }
    }

    &[disabled] {
        background-image:   linear-gradient(to right, $Cloudy-blue 100%, $Cloudy-blue 100%),
                            linear-gradient(to top, $Cloudy-blue 100%, $Cloudy-blue 100%),
                            linear-gradient(to left, $Cloudy-blue 100%, $Cloudy-blue 100%),
                            linear-gradient(to bottom, $Cloudy-blue 100%, $Cloudy-blue 100%);
        + label {
            color: $Cloudy-blue;
        }
    }

    &.ng-invalid.ng-dirty {
        color: $Pinkish-orange;
        border-color: $Pinkish-orange;
        background-image:   linear-gradient(to right, $Pinkish-orange 100%, $Pinkish-orange 100%),
                            linear-gradient(to top, $Pinkish-orange 100%, $Pinkish-orange 100%),
                            linear-gradient(to left, $Pinkish-orange 100%, $Pinkish-orange 100%),
                            linear-gradient(to bottom, $Pinkish-orange 100%, $Pinkish-orange 100%);
        outline: 0 none;

        &:hover {
            box-shadow: inset 0 -2px $Pinkish-orange;
        }

        ~ label {
            color: $Pinkish-orange;
        }
    }
}

select:not([no-border]) {
    border: 1px solid $Insurelynk-blue;
    border-radius: 0;
    position: relative;

    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%235a95cf'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;

    &:hover:not([disabled]), &:focus:not([disabled]) {
        box-shadow: inset 0 0 0 1px $Insurelynk-blue;
        @include transition('box-shadow 0.5s');
    }

    &[disabled] {
        border: 1px solid $Cloudy-blue;
        background: $Cloudy-blue;

        &:hover, &:focus {
            box-shadow: inset 0 0 0 1px $Cloudy-blue;
            @include transition('box-shadow 0.5s');
        }
    }

    &.ng-invalid.ng-dirty {
        color: $Pinkish-orange;
        border-color: $Pinkish-orange;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23ff754f'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
        background-size: 0.8em;
        background-origin: padding-box;
        background-position: right 2% top 65%;
        
        &:hover, &:focus {
            box-shadow: inset 0 0 0 1px $Pinkish-orange;
            @include transition('box-shadow 0.5s');
        }

        + label {
            color: $Pinkish-orange;
        }
    }

    & {
        background-size: 0.8em;
        background-origin: padding-box;
        background-position: right 2% top 65%;
    }

    &.input-sm {
        padding: 2px 10px;
    }

    &[no-caret] {
        background: none;
    }

    &::-ms-expand {
        display: none;
    }
}

.mc-field-group {
    margin: 20px 0;
}

hr {
    border-color: $Insurelynk-blue;
}