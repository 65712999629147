.userContainer {
  
  .specialCol{
    padding-left: 0px;
    padding-right: 0px;
  }

  .centerBoxParent {
    position: relative;
  }

  .centerBoxChild {
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translateY(-50%);
  }

  .defaultMsg{
        margin-top: 301px;
        text-align: center;
  }

  .input-has-error {
    color: $Pinkish-orange !important;
    border-color: $Pinkish-orange;
    // box-shadow: 0 1px 1px rgba(191, 35, 35, 0.075) inset, 0 0 8px rgba(191, 74, 74, 0.6);
    outline: 0 none;
  }

  .input-has-no-error {
    color: $Insurelynk-blue;
    border-color: $Insurelynk-blue;
    // box-shadow: 0 1px 1px rgba(35, 191, 74, 0.075) inset, 0 0 8px $Myhealthlynk;
    outline: 0 none;
  }

  h1,
  h3,
  h4 {
    color: $Insurelynk-blue;
  }

  .customH4 {
    color: $Dark-blue-grey;
    margin-bottom: 40px;
  }

  label {
    color: $Dark-blue-grey;
  }

  svg {
    z-index: -1;
    pointer-events: all;

    polyline {
      transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
    }
  }

  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px transparent, 0 0 8px transparent;
    box-shadow: inset 0 1px 1px transparent, 0 0 8px transparent;
  }

  .form-group {
    position: relative;
  }

  #submitRow {
    text-align: center;
    margin-top: 5%;

    .btn-info {
      background-color: $Insurelynk-blue;
      border-color: $Insurelynk-blue;
      width: 30em;
      border-radius: 20px;

      &:hover {
        background-color: $Pale-grey;
        border-color: $Insurelynk-blue;
        color: $Dark-blue-grey;
      }
    }
  }

  /*###########################
    ###### Radio Buttons ######
    ###########################*/
  #custom_width_svg_container {
    padding: 1px;
  }

  #customColWidthSvg {
    width: 36%;
    height: 82px;
    margin-top: 2.5em;
    position: relative;
    left: 4%;
    top: 0.6em;
  }

  #customColWidthRadio {
    width: 64%;
  }

  .addRadio {
    margin-top: 2.5em;
    $color-primary: #31CC89;
    $color-dark: #141D49;
    $color-grey: #CCCCCC;
    $component-font-size: 30px; // changing this will scale everything in the form up/down

    .c-form {
      color: $Dark-blue-grey;
      font-size: $component-font-size;
      margin: 2.5em auto;
    }

    .c-form__fieldset {
      border: none;
      padding: 0;

      &+& {
        margin-top: 2.5em;
      }
    }

    .c-form__group {
      margin-top: 0.5em;

      &:first-of-type {
        margin-top: 0;
      }
    }

    // visually hide the native checkbox and radio input
    .c-form__checkbox,
    .c-form__radio {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    // find the label that follows the checked checkbox / radio button in the DOM and show the tick icon
    .c-form__checkbox:checked+.c-form__label .c-form__checkbox-tick,
    .c-form__radio:checked+.c-form__label .c-form__radio-tick {
      stroke-dashoffset: 0;
    }

    // find the label thats follows the focused checkbox / radio button in the DOM and change the circle border color
    .c-form__checkbox:focus+.c-form__label .c-form__checkbox-square,
    .c-form__radio:focus+.c-form__label .c-form__radio-circle {
      stroke: $Insurelynk-blue;
      // fill: $Insurelynk-blue;
    }

    .c-form__label {
      cursor: pointer;
      display: block;
      font-size: 1em;
    }

    .c-form__label-text {
      margin-left: 0.25em;
      vertical-align: middle;
    }

    .c-form__checkbox-icon,
    .c-form__radio-icon {
      height: 1em;
      width: 1em;
      vertical-align: middle;
    }

    .c-form__checkbox-square,
    .c-form__radio-circle {
      fill: $color-grey;
      stroke: $color-grey;
      stroke-width: 1.5;
      transition: stroke 0.3s ease-in;
    }

    .c-form__checkbox-tick,
    .c-form__radio-tick {
      fill: none;
      stroke: $Insurelynk-blue;
      stroke-dasharray: 50;
      /* this is the length of the line */
      stroke-dashoffset: 50;
      /* this is the length of the line */
      stroke-width: 25%;
      transition: stroke-dashoffset 0.5s ease-in;
    }
  }

  /*###########################
      ###### End Radio Buttons ##
      ###########################*/
  //Radio arrangement
  .userTypeContainer {
    position: relative;
    height: 5em;

    #userTypeWord {
      position: absolute;
      top: 1em;
      left: 1em;
    }

    #userTypeWord3Branch {
      position: absolute;
      top: 0.1em;
      left: 1em;
    }

    #userTypeSvg {
      position: absolute;
      top: 0.85em;
      left: 5em;

      svg {
        height: 2em;
        width: 2em;
      }
    }

    #userTypeSvg3Branch {
      position: absolute;
      top: 0.85em;
      left: 4em;

      svg {
        height: 4em;
        // width: 2em;
      }
    }

    #userTypeRadios {
      position: absolute;
      top: -2.45em;
      left: 7.5em;
    }

    #userTypeRadios3Branch {
      position: absolute;
      top: -2.45em;
      left: 6.5em;
    }
  }

  .customMargin {
    margin-top: 4.3em;
    text-align: center;
  }

  #spaceA {
    margin-top: 1em;
  }

  #spaceB {
    margin-top: 7.2em;
  }

  .rigthButonsContainer {
    text-align: center;

    .btn-info {
      background-color: $Insurelynk-blue;
      border-color: $Insurelynk-blue;
      width: 100%;
      border-radius: 20px;

      &:hover {
        background-color: $Pale-grey;
        border-color: $Insurelynk-blue;
        color: $Dark-blue-grey;
      }
    }
  }

  .userGroupBox {
    width: 100%;
    height: 200px;
    border: 2px solid $Insurelynk-blue;
    overflow: scroll;
    text-align: center;

    .selectGroupBox {
      width: 100%;
      height: 100%;
      border: none;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }

  .discalimerStyle {
    text-align: center;
    margin-top: 2%;
  }

  .arrowBox {
    width: 100%;
    height: 200px;
    position: relative;
    text-align: center;

    .arrow {
      cursor: pointer;
      font-size: 18px;
      height: 18px;
    }

    #arrowRight {
      position: absolute;
      top: 72px;
    }

    #arrowLeft {
      position: absolute;
      top: 92px;
    }
  }
}

.modal-content {
  border: 5px solid $Insurelynk-blue;
  border-radius: 0;
  color: #1a2d40;

  input {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $Insurelynk-blue;
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    padding: 10px 5px;
    outline: 0;
    color: #31465b;
    border-radius: 0;
    box-shadow: inset 0 0 0 transparent;
  }

  select {
    box-shadow: inset 0 0 0 transparent;
    border: 1px solid $Insurelynk-blue;
  }

  .form-control {
    display: initial;
    width: 80%;
  }

  .form-group {
    position: relative;
    margin-bottom: 30px;
  }

  #closeBtn {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    // border-radius: 0;
    width: 10%;
    margin-top: 0;

    &:hover {
      background-color: $Pale-grey;
      color: $Ah-darkblue;
    }
  }


  .modal-header {
    text-align: center;

    h3 {
      display: inline;
    }

    .IconModal {
      top: -0.1em;
      margin-right: 0.45em;
      color: $Ah-darkblue;
    }
  }

  .modal-body {
    text-align: center;

    h5 {
      color: $Ah-darkblue;
    }
  }

  .modalBtn {
    color: #fff;
    width: 30%;
    border: none;
    padding: 10px;
    background-color: $Insurelynk-blue;
    border-radius: 25px;
    margin-top: 17px;
  }
}

.modal-dialog {
  margin-top: 3em;
}