.addressBox {
    margin: 5px;
    padding: 5px;
    height: 100px;

    border: 1px solid $Steel;
    border-radius: 2px;
    box-shadow: 0 -1px 0 $Steel;

    &:active {
        box-shadow: none;
        padding: 6px;
    }
}

.relCard {
    margin: 10px;
    padding: 20px;
    height: 400px;

    border: 1px solid $Pale-grey;
    border-radius: 10px;
    box-shadow: inset 0 2px $Steel;
    
    .cardTitle {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        color: $Steel;
    
        width: 100%;
        margin-bottom: 10px;
    }
}

.cardList {
    list-style: none;
    list-style-position: outside;

    margin: 0;
    padding: 0;

    font-size: 12px;
    font-weight: lighter;
    color: $Ah-darkblue;

    li {
        text-indent: -15px;

        &:before {
            content: '-';
            padding: 5px;
        }
    }
}

.selected {
    border: 2px solid $Insurelynk-blue;
    position: relative;

    .selectedIndex {
        position: absolute;
        top: 1px; right: 1px;

        background-color: $Insurelynk-blue;
        border-radius: 50%;
        color: white;
        text-align: center;
        width: 14px;
        font-size: 10px;
    }
}

.user_container {
    height: inherit;
    color: #FFF;
    padding: 0;

    h4 {
        font-weight:600;
        font-size: 16px;
        margin: 0;
    }

    p {
        font-size: 16px;
        margin: 2px 0;
    }

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .a-times {
        font-size: 35px;
        color: $Steel;
        margin-left: 5px;
    }

    &.address {
        background-color: white;
        color: black;
        min-height: 90px;
    }

    &:not(.address) {
        background-color: $Dark-blue-grey;
    }
}

.scrolling {
    overflow-x: hidden;
    overflow-y: auto;
}

.relationshipList {
    background-color: $Pale-grey;
    border: 1px solid $Insurelynk-blue;
    padding: 0 15px 0;
    height: 350px;
    max-height: 350px;

    .col-xs-12 {
        border-bottom: solid 1px $Insurelynk-blue;
    }
}

.addressScroll {
    max-height: 250px;
}

.relationshipScroll {
    max-height: 400px;
}

.no-gutters {
    padding-left: 0;
    padding-right: 0;
}

.transition {
    transition: all 0.5s ease;
}

.expand {
    height: auto !important;
}

.infoLabel {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
}

.rel-abbr {
    font-family: 'Martel';
    font-size: 26px; 
    font-weight: bold; 
    color: #5A95CF;
    width: 100%;
    text-align: right;
    line-height: 26px;
    padding: 10px 0 8px;
}

.mainOffice {
    font-size: 12px;
    font-weight: lighter;
    color: $Steel;
    text-align: right;
    width: 100%;
}

select:not(no-border).inlineSelect {
    width: 50%;
    margin: -5px 10px 0 0;
    padding: 2px;

    float: right;
    color: white;
    background-size: 0; // to hide caret
}

.providerSwitch {
    font-size: 12px;
    margin: -5px 0 10px;
}

.link {
    color: $Insurelynk-blue;
    text-decoration: underline;
}

.btn-info {
  background-color: $Dark-blue-grey;
  border-color: $Dark-blue-grey;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 600;

  &:hover, &:active, &:focus {
    background-color: $Insurelynk-blue;
    border-color: $Insurelynk-blue;
    color: white;
  }

  &[disabled] {
    background-color: $Cloudy-blue;
    border-color: $Cloudy-blue;
  }
}

.btnCancel {
  background-color: white;
  color: $Dark-blue-grey;
  border-color: $Dark-blue-grey;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 600;

  &:hover, &:active, &:focus {
    background-color: $Insurelynk-blue;
    border-color: $Insurelynk-blue;
    color: white;
  }
}