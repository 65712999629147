.userHistoryContainer{
  h1,
  h4 {
    color: $Insurelynk-blue;
  }


  //Table style
  .table-bordered {
    border: 1px solid $Insurelynk-blue;
  }

  table {
    border: 1px solid $Insurelynk-blue;
    text-align: center;

    tr {
      th {
        color: #fff;
        background-color: $Dark-blue-grey;
        border: 1px solid $Insurelynk-blue;
        text-align: center;

        input{
          color:#000;
        }
      }

      td {
        border: 1px solid $Insurelynk-blue;
      }
    }
  }

  tr:nth-child(even) {
    background-color: $Cloudy-blue;

    a {
      color: $Ah-darkblue;
      text-decoration: none;
    }
  }
}
