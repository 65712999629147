.provider_certification_files {
  margin-left: -50px;
  margin-right: -50px;

  .nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    display: -webkit-box;
    display: -moz-box;

    li {
      float: none;
    }
  }

  .history_header_style {
    float: left;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 20px;
  }

  .history_input_style{
    float: left;
    margin:0px 20px;

    select, input{
      width:200px !important;
      padding: 5px 16px 5px 5px !important;
    }

    .reset{
      border: 1px solid var(--Insurelynk-blue) !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      background-image: none !important;
      padding: 5px !important;
    }

    label {
      position: absolute;
      font-size:12px !important;
    }

    .to_and_from{
      height: 0px;
      width: 100%;
      position: absolute;
      background-color: #fff;
      z-index: 1;
      overflow: hidden;
      padding: 0px 10px;
    }

    .open{
      height: 100px;
      width: auto;
      border: 1px solid var(--Insurelynk-blue);
    }
  }

  .table-empty {
    display: flex;
    height: 200px;
    text-align: center;
    border: 1px solid var(--Insurelynk-blue);

    span { margin: auto; }
  }

  .compareFile {
    width: 100%;
    border: 1px solid black;
    max-height: 85%;

    &[data*=".pdf"], &[ng-attr-data*=".pdf"] {
      height: 85%;
    }
  }

  .modal-flex {
    display: flex;
    flex-direction: column;

    .row {
      margin: 0 20px;
    }
  }

  .modalTitle {
    font-family: 'Martel';
    font-size: 26px;
    font-weight: bold;
    text-transform: uppercase;

    .abartys-icon {
      font-size: 40px;
      color: var(--Steel);
      margin-top: -10px;
    }
  }

  .rejectQueue {
    margin: 10px 0;
    display: inline-block;
    width: 100%;
    font-size: 12px;
    text-align: left;

    .abartys-icon {
      font-size: 30px;
      color: var(--Steel);
    }

    .queueTitle {
      color: var(--Insurelynk-blue);
      font-size: 16px;
      text-transform: uppercase;
      padding-right: 15px;
    }

    .edit {
      color: var(--Insurelynk-blue);
      text-transform: none;
    }

    input {
      border: 2px solid var(--Insurelynk-blue);
      font-size: 12px;
      height: 20px;
    }
  }

  .file_action {
    justify-self: flex-end;

    button {
      border-radius: 20px;
      font-size: 18px;
      text-transform: uppercase;
      width: 30%;
    }

    .btn-accept {
      background-color: var(--Dark-blue-grey);

      &:hover {
        background-color: var(--Myhealthlynk);
        color: white;
      }
    }

    .btn-reject {
      background-color: white;
      border: 1px solid var(--Dark-blue-grey);
      color: var(--Dark-blue-grey);

      &:hover {
        background-color: var(--pinkish-orange);
        border: 1px solid var(--pinkish-orange);
        color: white;
      }
    }

    .btn-continue {
      &:hover {
        color: white;
      }
    }

    .btn-warning {
      background-color: var(--pinkish-orange);
    }
  }

  .notification_button {
    border-radius: 25px;
    background-color: var(--Dark-blue-grey);
    text-transform: uppercase;
    margin-top: 20px;
    padding: 10px 25px;
  }

  .table_action {
    padding: 0px 10px !important;
    text-align: center;
    color: var(--Insurelynk-blue);

    .btn {
      width: 50%;
      border: 0;
      margin: 0;
      border-radius: 0;
      float: left;
      height: 55px;
    }

    .btn-warning {
      background-color: var(--pinkish-orange);
      border-color: var(--pinkish-orange);
    }
  }

  .container {
    width: 100%;
  }

  .userNameStyle {
    color: var(--Insurelynk-blue);

    a {
      cursor: pointer;

      &:hover {
        color: var(--Dark-blue-grey);
      }
    }

    i {
      position: relative;
      font-size: 18px;
      bottom: 3px;
    }
  }

  .history_styles {
    padding: 10px 5vw 10px 15px;
    margin-right: 0;

    .history_button {
      font-weight: 600;
      font-size: 12px;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      color: var(--Cloudy-blue);
      text-shadow: 1px 1px var(--Steel);
    }

    .history_active {
      color: var(--Insurelynk-blue);
      text-shadow: none;
    }
  }

  //new panels
  .panel-default>.panel-heading {
    color: #fff;
    background-color: var(--Dark-blue-grey);
    border-color: var(--Insurelynk-blue);
  }

  .panel-default {
    border-color: var(--Insurelynk-blue);
  }

  .history_container{
    max-height: 300px;
    overflow-y: scroll;
    padding: 0px 60px;
    background-color: var(--Steel);

    table {
      position: relative;
      border-collapse: separate;

      .main_header {
        position: sticky;
        top: 0;
        border: 1px solid var(--Insurelynk-blue);
      }

      .sub_header {
        border: 1px solid var(--Insurelynk-blue);
        position: sticky;
        top: 36px;

        @media not all and (min-resolution:.001dpcm) {
          @supports (-webkit-appearance:none) {
            & {
              position: initial;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 5px 10px !important;
            text-align: center;

            &:first-child{
              text-align: left;
            }
          }
        }
      }
    }
  }

  //new navs
  .nav {
    border: 1px solid var(--Insurelynk-blue);
    border-bottom: 1px solid var(--Insurelynk-blue);
    font-size: 30px;

    li {
      border-right: 1px solid var(--Insurelynk-blue);
      position: relative;

      .counter {
        font-size: 15px;
        background-color: var(--pinkish-orange);
        color: #FFF;
        width: 25px;
        line-height: 25px;
        text-align: center;
        border-radius: 100%;
        vertical-align: middle;
      }
    }

    .active {
      a {
        color: var(--Insurelynk-blue);
        background-color: var(--Dark-blue-grey);
        border-radius: 0;
        border: 1px solid var(--Insurelynk-blue);
        border-bottom-color: transparent;
        width: 100%;

        &:hover {
          background: var(--Dark-blue-grey);
          color: var(--Insurelynk-blue);
        }

        &:focus {
          background: var(--Dark-blue-grey);
          color: var(--Insurelynk-blue);
        }
      }
    }

    a {
      color: var(--Insurelynk-blue);
      border-radius: 0;
      display: block;
      font-size: 1em;
      font-weight: bold;

      // padding: 10px 86.45px;
      &:hover {
        color: var(--Insurelynk-blue);
        background: var(--Pale-grey);
        width: 100%;
        border-bottom: 1px solid var(--Insurelynk-blue);
      }
    }
  }

  //new table style
  table {
    background-color:#fff;
    margin:0;

    tr{
      td{
        h1,
        h2,
        h3,
        h4,
        h5 {
          margin-top:10px;
          margin-bottom:6px;
        }
        padding:0px !important;
        vertical-align: middle;
      }
    }

    .datePos {
      text-align: center;
    }

    .table_date_style {
      padding: 0;
      position: relative;

      .right {
        position: relative;
        float: right;
        width: 55px;
        height: 35px;
        background-color: var(--Dark-blue-grey);
        color: #FFF;

        .abartys-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 30px;
        }
      }

      .left {
        overflow: hidden;
      }

      .calendar {
        width: 55px;
        height: 55px;
        background-color: var(--Dark-blue-grey);
        position: absolute;
        right: 0px;
        top: 0;
        color: #FFF;
        z-index: 0;

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 22px;
        }
      }

      input {
        width: 100%;
        height: 35px;
        border: none;
        border-radius: 0px;
        padding: 15px 10px;
        background: transparent;
        z-index: 1;
        position: relative;
      }
    }


    // font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid var(--Insurelynk-blue);
    border-top: 1px solid var(--Insurelynk-blue);

    a {
      color: var(--Insurelynk-blue) !important;
      text-decoration: none;
    }

    .downloadViewStyle {
      text-align: center;
      padding: 0px;

      button {
        background-color: var(--Dark-blue-grey);
        border: none;
        color: #fff;
        width: 80%;
        height: 55px;
      }

      select {
        width: 100%;
      }

      .dropZone {
        float: left;
        color: var(--Dark-blue-grey);
        height: 35px;
        width: 100%;
        // background-color: var(--Dark-blue-grey);
        margin: 0 auto;
        padding: 2px 7px;
        line-height: 35px;

        .zone {
          height: 100%;
          width: 100%;
        }
      }

      .file_selected {
        background-color: var(--Insurelynk-blue);
        color:#fff;
      }
    }

    thead {
      tr {
        th {
          text-align: center;
          border-bottom: none;
          text-transform: uppercase;
        }

        td {
          border-top: none;
        }
      }
    }

    th {
      color: #fff;
      background-color: var(--Dark-blue-grey);
    }

    td {
      .statusCircle {
        width: 100%;
        height: 35px;
        position: relative;

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%)
        }

        .red {
          color: var(--pinkish-orange);
        }

        .green {
          color: var(--Myhealthlynk);
        }

        .yellow {
          color: var(--providerLynk);
        }
      }

      .download_col{
        width: 100%;
        height: 35px;
        position: relative;

        i {
          font-size:18px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%)
        }
      }
    }

    td,
    th {
      border: 1px solid var(--Insurelynk-blue);
      text-align: left;
      padding: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }


    tr:nth-child(even) {
      background-color: var(--Pale-grey);

      a {
        color: #000000;
        text-decoration: none;
      }
    }
  }


  .linkElement {
    text-align: right;

    a {
      cursor: pointer;

      &:hover {
        color: var(--Insurelynk-blue);
      }
    }

    h6 {
      margin-top: 8%;
    }
  }

  .certification-col {
    .header-row {
      background-color: var(--Dark-blue-grey);
      color: #fff;
      padding: 10px 15px;
      border: 2px solid var(--Insurelynk-blue);
      border-width: 2px 2px 0px 2px;
      overflow: hidden;

      h4 {
        margin: 0;
        float: left;
        font-size: 19px;
      }

      i {
        float: right;
        font-size: 19px;
      }
    }

    .certification-list {
      border: 2px solid var(--Insurelynk-blue);
      list-style: none;
      padding: 0;
      max-height: 350px;
      min-height: 350px;
      overflow-x: scroll;

      li {
        background-color: #fff;
        display: block;
        padding: 10px 0px;
        border: 1px solid;
        border-width: 4px 2px 2px 2px;
        border-color: var(--Cloudy-blue);
        margin: 5px;
        height: 120px;

        .icon-container,
        .name-container,
        .controll-container {
          // height: 100%;
        }

        .icon-container {
          position: relative;

          i {
            position: absolute;
            font-size: 25px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .name-container {
          position: relative;

          .item-text {
            width: 100%;
            position: absolute;
            font-size: 15px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            // background: none;
            // border: none;
          }

          i {
            position: absolute;
            right: -16px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 20px;
            z-index: 1;
          }
        }

        .controll-container {
          text-align: right;

          label {
            margin-bottom: 0;
          }

          .days {
            .selected {
              color: var(--Insurelynk-blue);
              font-weight: 600;
            }
          }

          .expires_false {
            color: var(--Steel);
            font-weight: 600;
          }

          .expires_true {
            color: var(--Insurelynk-blue);
            font-weight: 600;
          }

          .days.expires_false {
            a {
              color: var(--Cloudy-blue);
            }
          }

          input {
            padding: 0px 1px;
            width: 90%;
          }

          select {
            -webkit-appearance: none;
            border-radius: 0;
            border-width: 0 0 0 0;
            border-style: solid;
            border-color: var(--Insurelynk-blue);
            box-shadow: none;
            -webkit-box-shadow: none;
            background: none;
          }
        }
      }
    }
  }

  .linkStyle {
    cursor: pointer;
    color: var(--Insurelynk-blue) !important;
  }

  .yellow {
    color: var(--providerLynk) !important;
  }

  .denied {
    color: var(--pinkish-orange) !important;
  }

  .pending {
    color: var(--providerLynk) !important;
  }

  .accepted {
    color: var(--Insurelynk-blue) !important;
  }

  //Sidenav Style
  .btn-info {
    background-color: var(--Dark-blue-grey);
    border-color: var(--Dark-blue-grey);
    border-radius: 50px;

    &:hover {
      background-color: var(--Insurelynk-blue);
      border-color: var(--Insurelynk-blue);
    }
  }

  a,
  a:focus,
  a:hover {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }

  .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
  }

  i,
  span {
    display: inline-block;
  }

  /* ---------------------------------------------------
          CONTENT STYLE
      ----------------------------------------------------- */
  #content {
    padding: 20px;
    // min-height: 100vh;
    transition: all 0.3s;
  }

  .official-records {
    tr th {
      background-color: var(--Insurelynk-blue);
    }

    tr td {
      border: none;
      vertical-align: baseline;

      &[ng-click] { cursor: pointer; }
    }

    .dea-summary {
      display: flex;
      height: 40px;
      padding-top: 10px;

      .fas {
        margin: 0 20px 0 auto;
        font-size: 2rem;
        color: var(--Insurelynk-blue);
      }
    }

    .dea-details {
      height: 0;
      overflow: hidden;
      transition: 200ms;

      &.open {
        height: 75px;
      }
    }
  }

  .icon-dea {
    width: 1%;

    img {
      width: 30px;
      margin: 10px 15px;
    }
  }

  .keyvalue-columns {
    width: 50%;
    white-space: initial;

    div {
      display: inline-block;
      width: 50%;

      &:nth-child(odd) { font-weight: 300; }
      &:nth-child(even) { font-weight: 500; }
    }
  }
}