.mainForm {
  .customGroup {
    padding-right: 10px;
    padding-left: 0;
  }

  .customSsnWidth {
    width: 33%;
    padding-right: 1.5%;
    padding-left: 1.5%;
  }

  #ssnCol {
    padding-right: 0px;
    padding-left: 0px;
  }

  .centerPlaceholder {
    input[placeholder] {
      text-align: center;
    }

    ::-webkit-input-placeholder {
      text-align: center;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      text-align: center;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      text-align: center;
    }

    :-ms-input-placeholder {
      text-align: center;
    }
  }

  .form-group {
    #ssn1 {
      width: 100%;
      float: left;
    }

    #ssn2 {
      // margin: 0 5%;
      width: 100%;
      float: left;
    }

    #ssn3 {
      width: 100%;
      // width: calc(100% - 164px);
      float: left;
    }
  }

  .customAddNewGroup {
    padding-right: 0;
    padding-left: 0;
  }

  .mobileDelete {
    text-align: right;
  }

  .addressHeading {
    text-align: center;

    h3 {
      color: $Insurelynk-blue;
      margin-top: 20%;
    }
  }

  .officeHeading {
    h3 {
      color: $Insurelynk-blue;
    }
  }

  .selectStyle {
    -webkit-appearance: none;
    border-radius: 0;
    border-width: 0 0 2px 0;
    border-style: solid;
    border-color: $Insurelynk-blue;
    box-shadow: none;
    -webkit-box-shadow: none;
    background: none;
  }

  .panel-default {
    .panel-heading {
      color: $textWhite;
      background-color: $Insurelynk-blue;
      border-color: $Insurelynk-blue;

      a {
        &:focus {
          text-decoration: none;
          // color: $Dark-blue-grey
        }

        &:hover {
          text-decoration: none;
          // color: $Dark-blue-grey
        }
      }
    }
  }

  #submitRow {
    text-align: center;
    margin-top: 5%;

    .btn-danger {
      width: auto;
      border-radius: 20px;
    }

    .btn-info {
      background-color: $Insurelynk-blue;
      border-color: $Insurelynk-blue;
      width: auto;
      border-radius: 20px;

      &:hover {
        background-color: $Pale-grey;
        border-color: $Insurelynk-blue;
        color: $Dark-blue-grey;
      }
    }
  }
}
