.notification-top-bar {
    height: 40px;
    line-height: 40px;
    width: 100%;
    background: var(--providerLynk);
    text-align: center;
    color: #FFFFFF;
    font-weight: lighter;
    font-size: 14px;

    @media screen and (max-width: 768px) {
        height: 30px;
        line-height: 30px;
    }

    p {
        padding: 0;
        margin: 0;

        a {
            display: inline-block;
            padding: 3px 10px;
            border-radius: 10px;
            background: #FFF;
            color: var(--Dark-blue-grey);
            font-weight: bold;
            text-decoration: none;
            line-height: 15px;
        }
    }

    .btn_compliance {
        cursor: pointer;
    }
}